import api from './api'

const repo =  {

  helloWorld: async () => {
    return api.get(`api/hello-world`)
  },

  //Authentication
  isAuthenticated: async()=>{
    await api.get('sanctum/csrf-cookie')
    return api.get('api/auth/validate')
  },

  generateSmsCode: async(user)=>{
    await api.get('sanctum/csrf-cookie')
    return api.post('api/auth/generate-sms-code',user)
  },

 signIn: async(user)=>{
    await api.get('sanctum/csrf-cookie')
    return api.post('api/auth/sign-in-sms',user)
  },

  signOut: async()=>{
    return api.get('api/auth/sign-out')
  },

  //On Boarding

  search: async (query) => {
    return api.get(`api/search?query=${query}`)
  },

  updateNumber: async (user) => {
    return api.post(`api/auth/update-cellnumber`,user)
  },


  getNotifications: async () => {
    return api.get(`api/notifications/user-notifications`)
  },

  callMe: async (contact) => {
    return api.post(`api/contact/call-me`,contact)
  },

  //Accounts
  accounts: async () => {
    return api.get('api/accounts');
  },

  account: async (id) => {
    return api.get('api/accounts/'+id);
  },

  updateAcknowledgement: async (id) => {
    return api.get('api/accounts/'+id+'/update-acknowledgement');
  },


  //Payments
  setupImmediatePayment: async (amount,account) => {    
    return api.post('api/payments/'+account+'/immediate/setup',{amount:amount,account:account});
  },
  debitOrder: async (account,debitOrder) => { 
    return api.post('api/payments/'+account+'/debit-order',debitOrder);
  },
  eftInstore: async (account,details) => {  
    return api.post('api/payments/'+account+'/eft-instore',details);
  },
  eftBankingDetails: async (id) => {
    return api.get('api/payments/'+id+'/eft-banking-details');
  },
  getPayment: async (id,accountId) => {
    return api.get('api/payments/details/'+id+'/'+accountId);
  },
  getBankingParameters: async () => {
    return api.get('api/payments/banking-parameters');
  },

  //surveys
  getSurvey: async (type) => {
    return api.get('api/surveys/'+type);
  },
  surveyResult: async (result) => {
    return api.post('api/surveys/'+result.survey_id+'/results',result);
  }
}

export default repo;
