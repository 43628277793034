import { Heading } from 'grommet'
import React from 'react'

interface Props {
  
}

const Disclaimer: React.FC<Props> = () => {
  return (
    <div>
      <Heading level={1}>DISCLAIMER</Heading>
      <p>
      HP Attorneys  makes no representations and/or guarantees as to the accuracy of materials on this website, and you acknowledge and agree that HP Attorneys  Attorneys, representatives, agents, attorneys, directors, candidate attorneys and/or employees will not be liable for any loss or damages, including direct, indirect, incidental, special, consequential or exemplary damages, or damages for loss of profits, goodwill, use, data or other intangible losses, resulting from your use of this website, or any HP Attorneys  Attorneys product on this website. You acknowledge that HP Attorneys  expressly disclaims all warranties including fitness for purpose and non-infringement.</p>
      
      <p> Please note that HP Attorneys  Attorneys does not represent that this website complies with all laws and ethical rules of any jurisdiction other than that of South Africa and if any provisions of this agreement as to the terms and conditions of your use of this website or products on it are invalid under South African law,HP Attorneys ’ liability is limited to the extent permitted by that law.
      </p>
    </div>
  )
}

export default Disclaimer
