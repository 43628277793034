import React, { useState, useEffect, useContext } from 'react'
import { Box, Heading, Paragraph, Text, ResponsiveContext, Tabs, Tab, Button } from 'grommet';
import { Link } from 'react-router-dom'
import { useParams } from "react-router-dom";
import repo from '../../api/repo'
import Toast, { Statuses } from '../../components/Toast'
import Spinner from '../../components/Spinner'
import { Context as AccountsContext } from '../../contexts/AccountsContext'
import { useHistory } from "react-router-dom";
import moment from 'moment'

interface Props { }

const AccountPaymentOptions: React.FC<Props> = () => {

  let size = React.useContext(ResponsiveContext);
  if (size !== 'small') {
    size = 'medium'
  }

  const [loading, setLoading] = useState(false)
  let { id } = useParams();
  const [account, setAccount] = useState<any>({});
  const [accountFound, setAccountFound] = useState(false);
  const [error, setError] = useState({ show: false, message: '' })

  const accountsContext = useContext(AccountsContext)
  const { accounts, updateAccounts } = accountsContext
  const history = useHistory();


  useEffect(() => {

    async function fetchAccount() {
      setLoading(true);
      try {
        const { data } = await repo.account(id);
        if(moment(data.account.Matter.AodDate).isBefore(moment(), 'day') ){
          setLoading(false)
          history.push(`/app/accounts/${id}/acknowledge`)
        }else{
          setAccount(data.account);
          setAccountFound(true);
          setLoading(false)
        }
      } catch (e: any) {
        if (e.response) {
          setError({ show: true, message: e.response.data.message })
        }
      } 

    }

   
      fetchAccount()
  
    return () => { }
  }, [])

  return (
    <div>

      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => { setError({ show: false, message: "" }) }} />

      {accountFound &&
        <Box border={true} pad="small" direction="row" className="rounded" style={{ flexWrap: "wrap" }} >

          <Link to="/app/accounts">
            <Text color="brand" size="small">
              <u>ACCOUNTS</u>
            </Text>
          </Link>

          <Link to={"/app/accounts/" + id + "/account"} >

            <Text color="brand" size="small" >
              &nbsp; / <u>{account.Matter.ClientSmsName.toUpperCase()}</u>
            </Text>

          </Link>

          <Text color="text-weak" size="small" style={{ marginTop: "2px" }}>
            &nbsp; / PAYMENTS
          </Text>


        </Box>
      }


      {!loading && accountFound &&

        <Box>

          <Box direction="row">
            <Box flex={{ grow: 1 }} align="start">
              <Heading level={1} margin={{ top: "medium", bottom: "none" }}>ARRANGE PAYMENT</Heading>
              <Box background="text" margin={{ top: "xsmall" }} className="rounded" pad="small" direction="row">
                <Text color="white">AC# {account.Matter.MatterID}</Text>
              </Box>
            </Box>
          </Box>


          {/* <Box margin={{ bottom: "medium", top: "large" }} border="bottom">


            <Heading level={5} color="text" margin="none">Pay and settle account in full</Heading>
            <Paragraph color="text-grey" size="small" margin="none">
              Make a full payment and a possible settlement discount might be applicable, contact us for more info.
            </Paragraph>

            <Link to={"/app/accounts/" + account.Matter.MatterID + "/settle"} >
              <Button primary margin={{ top: "xsmall", bottom: "medium" }}>
                <Box margin="small" direction="row" justify="center">
                  <Text color="white" weight="bold">SETTLE</Text>
                </Box>
              </Button>
            </Link>
            <Link to="/contact" >
              <Button primary margin={{ top: "xsmall", bottom: "medium" }}>
                <Box margin="small" direction="row" justify="center">
                  <Text color="white" weight="bold">CONTACT</Text>
                </Box>
              </Button>
            </Link>

          </Box> */}

          <Box margin={{ bottom: "medium", top: "large" }} border="bottom">
            <Link to={"/app/accounts/" + account.Matter.MatterID + "/payment-immediate"} >
              <Heading level={4} color="text" margin="none">Make a payment now</Heading>
              <Paragraph color="text-grey" size="small" margin="none">
                Make an immediate, once off payment using credit,debit or instant EFT.
              </Paragraph>
              <Button primary margin={{ top: "xsmall", bottom: "medium" }} style={{ width: '150px' }}>
                <Box margin="small" direction="row" justify="center">
                  <Text color="white" weight="bold">PAY NOW</Text>
                </Box>
              </Button>
            </Link>
          </Box>


          <Box margin={{ bottom: "medium" }} border="bottom">
            <Link to={"/app/accounts/" + account.Matter.MatterID + "/payment-debit-order"} >
              <Heading level={4} color="text" margin="none">Setup a Debit Order</Heading>
              <Paragraph color="text-grey" size="small" margin="none">
                Setup a payment plan via monthly debit orders.
              </Paragraph>
              <Button primary margin={{ top: "xsmall", bottom: "medium" }} style={{ width: '150px' }}>
                <Box margin="small" direction="row" justify="center">
                  <Text color="white" weight="bold">SETUP</Text>
                </Box>
              </Button>
            </Link>
          </Box>


          <Box margin={{ bottom: "medium" }} border="bottom">

            <Link to={"/app/accounts/" + account.Matter.MatterID + "/payment-instore-eft"} >
              <Heading level={4} color="text" margin="none">In Store or EFT payment plan</Heading>
              <Paragraph color="text-grey" size="small" margin="none">
                Make payments at your favourite retail store, or  via EFT on your chosen frequency.
              </Paragraph>
              <Button primary margin={{ top: "xsmall", bottom: "medium" }} style={{ width: '150px' }}>
                <Box margin="small" direction="row" justify="center">
                  <Text color="white" weight="bold">SETUP</Text>
                </Box>
              </Button>
            </Link>
          </Box>


          <Box background="background-back" width="100%" pad="medium" className="rounded" justify="center" direction="row" margin={{
            left: "auto",
            right: "auto",
            top: "medium",
          }}>

            {account.Matter.MatterCurrentOutstandingBalance > 0 && !account.Matter.ActivePlan &&
              <Paragraph>There is no active payment plan. Please choose a payment method above.</Paragraph>
            }
            {account.Matter.MatterCurrentOutstandingBalance <= 0 &&
              <Paragraph>There is no  payment required.</Paragraph>
            }

            {account.Matter.MatterCurrentOutstandingBalance > 0 && account.Matter.ActivePlan &&
              <Paragraph>There is an active payment plan. But you are able to make further payments.</Paragraph>
            }
          </Box>

        </Box>



      }
      {loading &&
        <Box margin="medium">
          <Spinner height="64" width="64" />
        </Box>
      }


    </div>
  )
}

export default AccountPaymentOptions
