import React, { createContext, useState } from 'react';


interface PropsAccounts{
  accounts:any[],
  updateAccounts?:any
}

interface PropsProvider {
  children:any,
 }

 /**
  * 
  */
export const Context = createContext<PropsAccounts>({accounts:[]});

/**
 * 
 * @param param0 
 */
export const Provider: React.FC<PropsProvider> = ({children}) => {

  const [accounts,setAccounts] = useState([]) 

  const updateAccounts = (accounts) => {
    setAccounts(accounts)
  }
 
  return(
    <Context.Provider value={{accounts,updateAccounts}}>
      {children}
    </Context.Provider>
  )
}

//export const { Consumer } = Context;






