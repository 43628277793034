import React, { useContext } from 'react'
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";


import { Context as UserContext } from './contexts/UserContext'
import { Provider as OnBoardContextProvider } from './contexts/OnBoardContext'
import { Provider as AccountsProvider } from './contexts/AccountsContext'
import { Provider as UserContextProvider } from './contexts/UserContext'
import { Provider as PaymentProvider } from './contexts/PaymentContext'

//Layouts
import OnBoardingLayout from './layouts/OnBoardingLayout'
import AppLayout from './layouts/AppLayout'

// views guest
import About from './views/About'
import AboutUs from './views/AboutUs'
import Contact from './views/Contact'
import Terms from './views/Terms'
import Disclaimer from './views/Disclaimer'
import Privacy from './views/Privacy'
import OnBoardSearch from './views/onBoard/OnBoardSearch'
import OnBoardSearchMatched from './views/onBoard/OnBoardSearchMatched'
import OnBoardSearchNoMatch from './views/onBoard/OnBoardSearchNoMatch'
import OnBoardSmsVerification from './views/onBoard/OnBoardSmsVerification'
import OnBoardUpdateNumber from './views/onBoard/OnBoardUpdateNumber'

//views auth
import Accounts from './views/accounts/Accounts'
import Account from './views/accounts/Account'
import Acknowledge from './views/accounts/Acknowledge'

import AccountPaymentOptions from './views/accounts/AccountPaymentOptions'
import PaymentResult from './views/accounts/PaymentResult'
import PaymentImmediate from './views/accounts/PaymentImmediate'
import PaymentInstoreEft from './views/accounts/PaymentInstoreEft'
import PaymentDebitOrder from './views/accounts/PaymentDebitOrder'

import Notifications from './views/profile/Notifications'
import Profile from './views/profile/Profile'


interface Props { }

const AppRouter: React.FC<Props> = () => {

  return (

    <UserContextProvider>
      <Switch>
        <GuestRoute exact path={[
          '/',
          '/matched',
          '/no-match',
          '/sms-verification',
          '/update-number'
        ]}>
          <OnBoardingLayout>
            <OnBoardContextProvider>
              <Switch>
                <Route path='/' exact component={OnBoardSearch} />
                <Route path='/matched' exact component={OnBoardSearchMatched} />
                <Route path='/no-match' exact component={OnBoardSearchNoMatch} />
                <Route path='/sms-verification' exact component={OnBoardSmsVerification} />
                <Route path='/update-number' exact component={OnBoardUpdateNumber} />
              </Switch>
            </OnBoardContextProvider>
          </OnBoardingLayout>
        </GuestRoute>

        <PrivateRoute path="/app/:path?">
          <AppLayout>
            <AccountsProvider>
              <Switch>
                
                <Route path='/app/accounts' exact component={Accounts} />
                <Route path='/app/accounts/:id?/acknowledge' exact component={Acknowledge} />
                <Route path='/app/accounts/:id?/account' exact component={Account} />

                <Route path='/app/notifications' exact component={Notifications} />
                <Route path='/app/profile' exact component={Profile} />

                <PaymentProvider>
                  <Route path='/app/accounts/:id?/payment-options' exact component={AccountPaymentOptions} />
                  <Route path='/app/accounts/:id?/payment/:payment_id?/result' exact component={PaymentResult} />
                  <Route path='/app/accounts/:id?/payment-immediate' exact component={PaymentImmediate} />
                  <Route path='/app/accounts/:id?/payment-instore-eft' exact component={PaymentInstoreEft} />
                  <Route path='/app/accounts/:id?/payment-debit-order' exact component={PaymentDebitOrder} />                  
                </PaymentProvider>

               

              </Switch>
            </AccountsProvider>
          </AppLayout>
        </PrivateRoute>

        <Route path={[
          '/about',
          '/about-us',
          '/contact',
          '/terms-and-conditions',
          '/privacy-policy',
          '/disclaimer'
        ]}>
          <OnBoardingLayout>
            <Switch>
              
              <Route path='/about' exact component={About} />
              <Route path='/about-us' exact component={AboutUs} />
              <Route path='/contact' exact component={Contact} />
              <Route path='/terms-and-conditions' exact component={Terms} />
              <Route path='/privacy-policy' exact component={Privacy} />
              <Route path='/disclaimer' exact component={Disclaimer} />
            </Switch>
          </OnBoardingLayout>
        </Route>

      </Switch>
    </UserContextProvider>
  )
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute: React.FC<any> = ({ children, ...rest }) => {

  const userContext = useContext(UserContext);
  const { user } = userContext

  let authenticated = false;

  if (user.name) authenticated = true

  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}

//Only guest users can see, not signed in users
function GuestRoute({ children, ...rest }) {
  const userContext = useContext(UserContext);
  const { user } = userContext

  let authenticated = false;
  if (user.name) authenticated = true

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !authenticated ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/app/accounts",
                state: { from: location }
              }}
            />
          )
      }
    />
  );
}


export default AppRouter