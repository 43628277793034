import React, { useState,useRef,useContext,useEffect } from 'react'
import { Avatar, Box, Image, Paragraph, TextInput, Heading, Button, Keyboard } from 'grommet';
import { FormNextLink } from 'grommet-icons'
import {ReactComponent as UserSearchIcon} from '../../assets/svg/UserSearchIcon.svg'
import contact from '../../assets/img/contact.png'
import { Link,useHistory } from 'react-router-dom'
import repo from '../../api/repo'
import Spinner from '../../components/Spinner'
import Toast, { Statuses } from '../../components/Toast'
import {Context as OnBoardContext} from '../../contexts/OnBoardContext'

interface Props { }

const OnBoardingSearch: React.FC<Props> = () => {

  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ show: false, message: '' })
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory()
  const [found,setFound] = useState(false)

  const onBoardContext = useContext(OnBoardContext);
  const {user,updateUser} = onBoardContext
  

  const initSearch = async () => { 

    if(search===''){     
      setError({ show: true, message: "Please enter something" })      
      if(inputRef.current) inputRef.current.focus()
      return false;
    }

    if(search.length < 7){     
      setError({ show: true, message: "Please enter more characters." })      
      if(inputRef.current) inputRef.current.focus()
      return false;
    }

    setLoading(true)
    setError({ show: false, message: "" })

    try {
      const {data} = await repo.search(search);
      setFound(true)
      data.user.search = search
      updateUser(data.user)           
    } catch (e:any) {
      if(e.response){
      if(e.response.status === 400){
        
        if(e.response.data.message==="No Results found"){
          user.search = search
          updateUser(user)     
          history.push('/no-match')
        }else{
          setSearch('')   
          if(inputRef.current){inputRef.current.focus()}
          setError({ show: true, message: "Whoops something went wrong!" })
        }     
      }
      }else{
        setSearch('')   
        if(inputRef.current){inputRef.current.focus()}
        setError({ show: true, message: "Whoops something went wrong!" })
      }    
    } finally {
      setLoading(false)
    }

  }

  useEffect(() => {        
    if(found){
      history.push('/matched')   
    }     
    return () => { 
     setFound(false)
    }
    
  }, [found])


  return (

    <Box margin={{ top: "100px" }} width={{ width: "100%", max: "600px" }} alignSelf="center" className="border-desktop">
     
      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => {setError({ show: false, message: "" })}}/>

      <Avatar background="text" size="xlarge" alignSelf="center" margin={{ top: "-50px" }}>
        <UserSearchIcon fill="white"/>
      </Avatar>

      <Box alignSelf="center" alignContent="center">

        <Heading textAlign="center" size="medium" level={1} margin={{ bottom: "small" }} className="heading-h1" responsive={false}>GET STARTED</Heading>

        <Paragraph color="text-weak" margin="none" textAlign="center">
          Please enter your:
        </Paragraph>
        <Paragraph color="text-weak" margin="none" textAlign="center">
          South African <strong>ID Number</strong>,
        </Paragraph>
        <Paragraph color="text-weak" margin="none" textAlign="center">
          Passport or Business Registration Number
        </Paragraph>

        <Box margin={{ top: "medium" }} direction="row">
          <Keyboard onEnter={() => { initSearch() }}>
    
            <TextInput
              ref={inputRef as any}
              autoFocus
              placeholder=""
              value={search}
              onChange={event => setSearch(event.target.value)}
            />
          </Keyboard>
         
              {!loading &&
                        <Button primary margin={{ left: "small" }} onClick={() => initSearch()}>
                        <Box margin={{ left: "9px", right: "9px" }}>                   
                            <FormNextLink size="medium" color="white" />
                        </Box>
            
                      </Button>
              }
              {loading &&
                <Box margin={{ left: "9px", right: "9px" }}>                   
                  <Spinner height="32" width="32" />
                </Box>               
              }

           

        </Box>

         <Link to="/contact" >
          <Box alignContent="center">
            <Image
              margin={{
                left: "auto",
                right: "auto",
                top: "large",
                bottom: "-22px"
              }}
              src={contact}
            />
          </Box>

        </Link>

      </Box>

    </Box>
  )
}

export default OnBoardingSearch
