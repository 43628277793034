import React, { useState, useEffect, useContext } from 'react'
import { Box, Heading, Paragraph, Text, ResponsiveContext, Button, TextInput, RadioButtonGroup } from 'grommet';
import { Link } from 'react-router-dom'
import repo from './../../api/repo'
import Toast, { Statuses } from '../../components/Toast'
import Spinner from '../../components/Spinner'
import { useParams } from "react-router-dom";
import NumberFormat from 'react-number-format';
import payfast from '../../assets/img/payfast-logo.svg'
import { Context as AccountsContext } from '../../contexts/AccountsContext'
import { useHistory } from "react-router-dom";
import moment from "moment"
import CurrencyInput from 'react-currency-input-field';

interface Props { }

const PaymentImmediate: React.FC<Props> = () => {

  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState('')
  let { id } = useParams();
  const [account, setAccount] = useState<any>({});
  const [accountFound, setAccountFound] = useState(false);
  const [error, setError] = useState({ show: false, message: '' })

  const accountsContext = useContext(AccountsContext)
  const { accounts,updateAccounts } = accountsContext
  const history = useHistory();


  let size = React.useContext(ResponsiveContext);
  if (size !== 'small') {
    size = 'medium'
  }

  useEffect(() => {

    async function fetchAccount() {
      setLoading(true);
      try {
        const { data } = await repo.account(id);
        if(moment(data.account.Matter.AodDate).isBefore(moment(), 'day') ){
          setLoading(false)
          history.push(`/app/accounts/${id}/acknowledge`)
        }else{
          setAccount(data.account);
          setAccountFound(true);
          setLoading(false)
        }
      } catch (e: any) {
        setLoading(false)
        if (e.response) {
          setError({ show: true, message: e.response.data.message })
        }
      } 

    }

    fetchAccount()
   
    return () => { }
  }, [])


  const handleSubmit = async () => {

    setLoading(true)
    if (amount == "") {
      setLoading(false)
      setError({ show: true, message: "Please enter Amount!" })
    } else if (parseFloat(amount) < account.Matter.MinimumPayment) {
      setLoading(false)
      setError({ show: true, message: "Less then minimum amount!" })
    } else if (parseFloat(amount) > account.Matter.MatterCurrentOutstandingBalance) {
      setLoading(false)
      setError({ show: true, message: "Greater then outstanding amount!" })
    }
    else {
      try {
        const { data } = await repo.setupImmediatePayment(amount, id);
        window.location.href = data.payment_url;
      } catch (e: any) {
        setLoading(false)
        if (e.response) {
          setError({ show: true, message: "Whoops something went wrong!" })

        }
      }
    }



  }


  return (
    <div>

      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => { setError({ show: false, message: "" }) }} />

      {accountFound &&
        <Box border={true} pad="small" direction="row" style={{flexWrap:"wrap"}}  className="rounded">

      
            <Link to="/app/accounts">
              <Text color="brand" size="small">
                <u>ACCOUNTS</u> &nbsp;
              </Text>
            </Link>
          
            <Link to={"/app/accounts/" + id + "/account"} >
              <Text color="brand" size="small" >
                / <u>{account.Matter.ClientSmsName.toUpperCase()}</u>&nbsp;
              </Text>
            </Link>
         
            <Link to={"/app/accounts/" + id + "/payment-options"}>
              <Text color="brand" size="small" >
                / <u>PAYMENTS</u> &nbsp;
              </Text>
            </Link>
        

      
         
              <Text color="text-weak" size="small" style={{marginTop:"2px"}} >
                / IMMEDIATE
              </Text>
        

        </Box>
      }



      {!loading && accountFound &&

        <Box>

          <Box direction="row">
            <Box flex={{ grow: 1 }} align="start">
              <Heading level={1} margin={{ top: "large", bottom: "none" }}>PAYMENT</Heading>
              <Box background="text" margin={{ top: "xsmall" }} className="rounded" pad="small" direction="row">
                <Text color="white">AC# {account.Matter.MatterID}</Text>
              </Box>
            </Box>
          </Box>


          <Box pad="small" background="background-back" margin={{ bottom: "small", top: "medium" }} direction="row" className="rounded">
            <Box flex={{ grow: 1 }} pad="none">
              <Paragraph color="text-grey" margin="none">Outstanding</Paragraph>
            </Box>
            <Box flex={{ grow: 1 }} pad="none">
              <NumberFormat
                value={account.Matter.MatterCurrentOutstandingBalance}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'R '}
                decimalScale={2}
                fixedDecimalScale={true}
                renderText={formattedValue => <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{formattedValue}</Paragraph>}
              />
            </Box>
          </Box>

          {/* {account.Matter.MatterCurrentOutstandingBalance > 0 && account.Matter.SettlementDiscount > 0 &&

            <Box pad="small" border={{ color: 'brand' }} margin={{ bottom: "small", top: "small" }} direction="row" className="rounded">
              <Box pad="none">
                <Paragraph color="branch" margin="none">Settlement Discount</Paragraph>
                <Text color="text-grey" margin="none" size="x-small">This is the discounted amount to pay if you <strong>settle your account in full.</strong></Text>
              </Box>
              <Box flex={{ grow: 1 }} pad="none" >
                <NumberFormat
                  value={account.Matter.MatterCurrentOutstandingBalance - account.Matter.SettlementDiscount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'R '}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  renderText={formattedValue => <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{formattedValue}</Paragraph>}
                />
              </Box>
            </Box>
          } */}

          <Box direction="row">
            <Text color="text-weak" margin={{ left: "none", bottom: "medium", top: "medium" }}>
              Payment can be made using your credit card, debit card and instant EFT.
            </Text>
          </Box>


          <Heading level={5} color="text" margin={{ top: "medium", bottom: "xsmall" }}>Amount</Heading>
          <Box direction="row">
            <Box width="150px">

              <CurrencyInput
                className="form-control" 
                prefix="R"
                intlConfig={{ locale: 'en-US', currency: 'ZAR' }}
                decimalSeparator="." 
                groupSeparator=","
                placeholder="Amount"
                decimalsLimit={2}
                onValueChange={(value, name)  => value?setAmount(value):""}
              />

             </Box>
            <Box>
              {account.Matter.MinimumPayment > 0 &&
                <Text color="text-weak" margin={{ left: "medium", bottom: "xsmall" }}>
                  Minimum payment of

                  <NumberFormat
                    value={account.Matter.MinimumPayment}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'R '}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={formattedValue => <Text textAlign="end" className="font-weight-bold" color="text" margin="none"> {formattedValue} </Text>}
                  />

                  required
                </Text>
              }
            </Box>
          </Box>


         
            <Button onClick={() => handleSubmit()} primary margin={{ top: "medium", bottom: "medium" }} hoverIndicator={{ color: "status-ok" }} style={{width:'150px'}}  >
              <Box margin="small" direction="row" justify="center">
                {!loading ?
                  <Text color="white" weight="bold">PAY NOW</Text>
                  :
                  <Spinner color="white" />
                }

              </Box>              
            </Button>
        

            <Box margin={{ top: "medium", bottom: "medium" }}  border="top">
              </Box>
          
          <Box margin={{ top: "medium", bottom: "medium" }} width={{ max: "220px" }}>
            <img
              className="img-fluid"
              src={payfast}
            />
          </Box>

        </Box>
      }
      {loading &&
        <Box margin="medium">
          <Spinner height="64" width="64" />
        </Box>
      }


    </div>
  )
}

export default PaymentImmediate
