import React, { useState, useEffect } from 'react'
import { FormNext } from 'grommet-icons';
import { Box, Heading, TextArea, Paragraph, Text, Layer, Button } from 'grommet';
import { useHistory } from "react-router-dom";
import repo from "./../api/repo";
import { CircleQuestion } from 'grommet-icons'
import { Rating } from 'react-simple-star-rating'
import Toast, { Statuses } from '../components/Toast'
import Spinner from '../components/Spinner'

interface Props {
  surveyType: string,
  onComplete?(): any
}




const Survey: React.FC<Props> = ({ surveyType, onComplete }) => {

  const [survey, setSurvey] = useState<any>({});
  const [response, setResponse] = useState<any>({});
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [surveyDone, setSurveyDone] = useState(false);
  const [showQuestions, setShowQuestions] = useState(false);
  const [loading, setLoading] = useState(false)



  useEffect(() => {

    async function getSurvey() {

      try {
        const { data } = await repo.getSurvey(surveyType);
        if (data.survey != null) {

          setSurvey(data.survey)

          setResponse({
            survey_id: data.survey.id,
            answers: []
          })

          let answers: any = [];
          data.survey.questions.forEach(x => {
            answers.push({
              question_id: x.id, answer: null
            })
          })

          setResponse({
            ...response,
            answers: answers
          })



        } else {
          if (onComplete != null) {
            onComplete()
          }
        }
      } catch (e: any) {
        if (onComplete != null) {
          onComplete()
        }
      }
    }

    getSurvey();


    return () => { }
  }, [])

  useEffect(() => {
    if (survey.questions != null) {
      setShow(true);

    }

    return () => { }
  }, [survey])

  const handleRating = (rate: number, index: number) => {
    let answers = response.answers
    answers[index].answer = rate;
    setResponse({
      ...response,
      answers: answers
    })

  }

  const handleText = (text: string, index: number) => {
    let answers = response.answers
    answers[index].answer = text;
    setResponse({
      ...response,
      answers: answers
    })

  }

  const handleMultiChoice = (text: string, index: number) => {
    let answers = response.answers
    answers[index].answer = text;
    setResponse({
      ...response,
      answers: answers
    })

  }

  const declined = async () => {
    setShow(false)
    try {
      await repo.surveyResult({
        survey_id: survey.id,
        declined: true
      });

    } catch (e: any) {

    } finally {
      if (onComplete != null) {
        onComplete()
      }
    }
  }

  const submit = async () => {
    setShow(false)
    setLoading(true)
    try {
      await repo.surveyResult({
        survey_id: survey.id,
        declined: false,
        answers: response.answers
      });

    } catch (e: any) {

    } finally {
      setSurveyDone(true)
      setLoading(false)
      if (onComplete != null) {

        onComplete()

      }
    }
  }

  return (
    <div>

      {loading &&
        <Layer
          style={{
            width: '100%',
            height: '100%',
            margin: "auto",
            textAlign:'center'
          }}
        >
          <Box alignSelf='center'>
          <Paragraph textAlign='center'>Thank You, Processing Results.</Paragraph>
            <Spinner height="64" width="64" />
          </Box>
        </Layer>
      }

      <Toast status={Statuses.success} message="Thank You!" show={surveyDone} onClose={() => { setSurveyDone(false) }} />

      {show &&
        <Layer
          style={{
            width: '100%',
            maxWidth: '700px',
            margin: "auto"
          }}
        >
          {!showQuestions &&
            <Box pad="medium">

              <Box flex="shrink" alignSelf='center'>
                <CircleQuestion size="large" />
              </Box>


              <Paragraph textAlign='center'>{survey.description}</Paragraph>

              <Box flex="shrink" direction='row' alignSelf='center'>

                <Button primary onClick={() => setShowQuestions(true)} margin={{ left: "9px", right: "9px" }}>
                  <Box margin={{ left: "9px", right: "9px" }} pad="small">
                    <Text size="medium" color="white" >YES</Text>
                  </Box>
                </Button>

                <Button secondary onClick={() => declined()} margin={{ left: "9px", right: "9px" }} >
                  <Box margin={{ left: "9px", right: "9px" }} pad="small">
                    <Text size="medium"  >NO</Text>
                  </Box>
                </Button>


              </Box>
            </Box>
          }

          {showQuestions && response.answers.length > 0 &&

            <Box pad="medium">
              {survey.questions.map((x, i) => {

                return (

                  <div key={i} style={{ "borderBottom": "solid 1px #DDD", "paddingTop": "10px", "paddingBottom": "10px" }}>

                    <Text weight="bold" >{i + 1}: {x.question}</Text> <br /><br />

                    {x.type == 'star' &&
                      <Box direction='row' alignContent="center" >
                        <Text size='small' margin="small" color="text-weak">Bad</Text>
                        <Rating tooltipArray={['Poor', 'Below Average', 'Average', 'Good', 'Excellent']} onClick={(v) => { handleRating(v, i) }} ratingValue={response.answers[i].answer} />
                        <Text size='small' margin="small" color="text-weak">Excellent</Text>
                      </Box>

                    }
                    {x.type == 'multiple-choice' &&

                      <Box direction='row' wrap>

                        {x.choices.split(",").map((y, j) => {
                          return (<Button primary={response.answers[i].answer == y}
                            margin="xsmall"
                            onClick={() => { handleMultiChoice(y, i) }}
                            style={{ width: "fit-content", border: "solid 1px #DDD", "borderRadius": "20px", "padding": "3px 6px" }}
                            key={j}>
                            <Text weight="bold" size="small">{y}</Text></Button>)
                        })}
                      </Box>

                    }
                    {x.type == 'free-text' &&
                      <TextArea placeholder='Optional information' onChange={(e) => { handleText(e.target.value, i) }} value={response.answers[i].answer} />
                    }
                  </div>
                )




              })}

              <Box flex="grow" direction='row' justify="between">

                <Button primary onClick={() => submit()} margin={{ top: "15px" }} style={{ width: "fit-content" }}>
                  <Box pad="small" >
                    <Text size="medium" color="white" >SUBMIT</Text>
                  </Box>
                </Button>

                <Button secondary onClick={() => declined()} margin={{ top: "15px" }} style={{ width: "fit-content" }} >
                  <Box pad="small">
                    <Text size="medium"  >CANCEL</Text>
                  </Box>
                </Button>

              </Box>



            </Box>

          }


        </Layer>

      }



    </div>
  )
}



export default Survey
