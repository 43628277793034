import React from 'react'
import {
  Text,
  Box,
  Footer,
  ResponsiveContext,
  Image
} from 'grommet';

import {
  Link
} from "react-router-dom";

import google from './../assets/img/gplay.png';
import huawei from './../assets/img/huawei.png';
import apple from './../assets/img/istore.png';

interface Props { }

const OnBoardingLayout: React.FC<Props> = () => {

  const size = React.useContext(ResponsiveContext);


  return (

    <Footer background="lightGrey" pad="large" direction="column" margin={{ top: "large" }} border="top" >

      <Box margin={{ top: "large" }}>
        <Text textAlign="center" weight="bold" size="large" color="text">DOWNLOAD OUR APP</Text>
        <Text textAlign="center" color="text" margin={{ top: "x-small" }}>All the functionality of the portal in the convenience of an app.</Text>
      </Box>
      <Box  direction="row" wrap  justify='center' >

        <a href='https://play.google.com/store/apps/details?id=za.co.hpd.portal.app'>
          <Image
            src={google}
            margin={size=='small'?'xxsmall':'small'}
            height={size=='small'?36:64}
          />
        </a>

        <a href='https://appgallery.huawei.com/app/C105051755'>
          <Image
            src={huawei}
            margin={size=='small'?'xxsmall':'small'}
            height={size=='small'?36:64}
          />
        </a>

        <a href='https://apps.apple.com/za/app/hp-portal/id1598506502'>
          <Image
            src={apple}
            margin={size=='small'?'xxsmall':'small'}
            height={size=='small'?36:64}
          />
        </a>
      </Box>

      <Box margin={{ top: "medium" }}>
        <Text textAlign="center" size="small" color="text" margin={{ bottom: "none" }} className="pointer l-s-1 hl">
          <Link to="/terms-and-conditions" className="pointer col-text-link">T & C's</Link>
          |
          <Link to="/disclaimer" className="pointer col-text-link">DISCLAIMER </Link>
          |
          <Link to="/privacy-policy" className="pointer col-text-link">PRIVACY POLICY</Link>
        </Text>
        <Text textAlign="center" size="small" color="text-weak" margin={{ top: "5px" }} >
          © Copyright Hammond Pole. All Rights Reserved
        </Text>

      </Box>

    </Footer>

  )
}

export default OnBoardingLayout




