import React, { useState, useEffect, useContext } from 'react'
import { Box, Heading, Paragraph, Text, ResponsiveContext, TextInput, CheckBox, Button, Select } from 'grommet';
import { Link,useHistory } from 'react-router-dom'
import { useParams } from "react-router-dom";
import Spinner from '../../components/Spinner'
import repo from '../../api/repo'
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import Toast, { Statuses } from '../../components/Toast'
import { Context as AccountsContext } from '../../contexts/AccountsContext'
import moment from "moment"
import CurrencyInput from 'react-currency-input-field';

interface Props { }

const PaymentDebitOrder: React.FC<Props> = () => {

  let size = React.useContext(ResponsiveContext);
  if (size !== 'small') {
    size = 'medium'
  }

  const history = useHistory()
  const [loading, setLoading] = useState(false)
  let { id } = useParams();
  const [account, setAccount] = useState<any>({});
  const [accountFound, setAccountFound] = useState(false);
  const [eft, setEft] = useState<any>({
    acknowledged:false,
    amount:"",
    account_number:"",
    account_name:"",
    nationality_id:710,
    debit_today:false,
    cellphone_number:"",
    id_number:""
  });
  const [banks,setBanks] = useState<any>({});
  const [accountTypes,setAccountTypes] = useState<any>({})
  const [identificationTypes,setIdentificationTypes] = useState<any>({})
  const [nationalityTypes,setNationalityTypes] = useState<any>({})
  const [parametersFound,setParametersFound] = useState(false)
  const [error, setError] = useState({ show: false, message: '' })
  const [showTerms, setShowTerms] = useState(false)

  const accountsContext = useContext(AccountsContext)
  const { accounts,updateAccounts } = accountsContext

 
  
  useEffect(() => {

    async function fetchAccount() {
      setLoading(true);
      try {
        const { data } = await repo.account(id);

        if(moment(data.account.Matter.AodDate).isBefore(moment(), 'day') ){
          setLoading(false)
          history.push(`/app/accounts/${id}/acknowledge`)
        }else{
          setAccount(data.account);
          setAccountFound(true);
          fetchParameters()
          setLoading(false)
        }

      
      } catch (e:any) {
        setLoading(false)
        if (e.response) {
          setError({ show: true, message: e.response.data.message })
        }
      } 

    }
    fetchAccount()


    return () => { }
  }, [])


  

    async function fetchParameters() {
      setLoading(true);
      try {
        const { data } = await repo.getBankingParameters();
        setBanks(data.banks);
        setAccountTypes(data.account_types);
        setNationalityTypes(data.nationalities)
        setIdentificationTypes(data.identification_types)
        setParametersFound(true);
      } catch (e:any) {
        if (e.response) {
          setError({ show: true, message: e.response.data.message })
        }
      } finally {
        setLoading(false)
      }

    } 



  async function submit(){
    if(!eft.acknowledged){
      setError({ show: true, message: "Please accept terms." })
      return false;
    }

    if(eft.amount == "" || eft.banking_institution_id == null || eft.account_type_id == null || eft.day == null || eft.account_name == "" || eft.account_number == "" || eft.cellphone_number == "" || eft.id_number == "" || eft.nationality_id == null || eft.identification_type_id == null){
      setError({ show: true, message: "Please enter all fields." })
      return false;
    }

    

    if(parseFloat(eft.amount) < account.Matter.MinimumPayment){
      setError({ show: true, message: "Less then minimum amount!" })
      return false;
    }else if(parseFloat(eft.amount) > account.Matter.MatterCurrentOutstandingBalance){
      setError({ show: true, message: "Greater then outstanding amount!" })
      return false;
    }      

    if (eft.cellphone_number.length != 10) {
      setError({ show: true, message: "Please enter 10 digit cell number." })
      return false;
    }

    //eft.debit_today?eft.debit_today=1:eft.debit_today=0

    setLoading(true);
      try {
        const { data } = await repo.debitOrder(id,eft);
        history.push('/app/accounts/'+id+'/payment/'+data.payment_id+'/result');     
        setEft({})  
      } catch (e:any) {
        if (e.response) {
          setError({ show: true, message: e.response.data.message })
        }
      } finally {
        setLoading(false)
      }

  }

  return (
    <div>

      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => { setError({ show: false, message: "" }) }} />

      {accountFound &&
        <Box border={true} pad="small" direction="row" style={{flexWrap:"wrap"}}  className="rounded">

      
            <Link to="/app/accounts">
              <Text color="brand" size="small">
                <u>ACCOUNTS</u> &nbsp;
              </Text>
            </Link>
          
            <Link to={"/app/accounts/" + id + "/account"} >
              <Text color="brand" size="small" >
                / <u>{account.Matter.ClientSmsName.toUpperCase()}</u>&nbsp;
              </Text>
            </Link>
         
            <Link to={"/app/accounts/" + id + "/payment-options"}>
              <Text color="brand" size="small" >
                / <u>PAYMENTS</u> &nbsp;
              </Text>
            </Link>
        

      
         
              <Text color="text-weak" size="small" style={{marginTop:"2px"}} >
                / DEBIT ORDER
              </Text>
        

        </Box>
      }

      {!loading && accountFound && parametersFound &&

        <Box>

          <Box direction="row">
            <Box flex={{ grow: 1 }} align="start">
              <Heading level={1} margin={{ top: "large", bottom: "none" }}>DEBIT ORDER DETAILS</Heading>
              <Box background="text" margin={{ top: "xsmall" }} className="rounded" pad="small" direction="row">
                <Text color="white">AC# {id}</Text>
              </Box>
            </Box>
          </Box>


          <Box pad="small" background="background-back" margin={{ bottom: "small", top: "medium" }} direction="row" className="rounded">
            <Box flex={{ grow: 1 }} pad="none">
              <Paragraph color="text-grey" margin="none">Outstanding</Paragraph>
            </Box>
            <Box flex={{ grow: 1 }} pad="none">
            <NumberFormat
                    value={account.Matter.MatterCurrentOutstandingBalance}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'R '}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={formattedValue => <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{formattedValue}</Paragraph>}
                  />
            </Box>
          </Box>

        
          <Heading level={5} color="text" margin={{ top: "large", bottom: "xsmall" }}>Amount</Heading>
          <Box direction="row">
            <Box width="120px">
              <CurrencyInput
                className="form-control" 
                prefix="R"
                intlConfig={{ locale: 'en-US', currency: 'ZAR' }}
                decimalSeparator="." 
                groupSeparator=","
                placeholder="Amount"
                decimalsLimit={2}
                onValueChange={(value, name)  => value?setEft({...eft,amount:value}):""}
              />
            </Box>

            { account.Matter.MinimumPayment > 0 &&
              <Box>
                <Text color="text-weak" margin={{ left: "medium", bottom: "xsmall" }}>
                Minimum payment of 
                  
                  <NumberFormat
                      value={account.Matter.MinimumPayment}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'R '}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      renderText={formattedValue => <Text textAlign="end" className="font-weight-bold" color="text" margin="none"> {formattedValue} </Text>}
                    />
                  
                  required
                </Text>
              </Box>
            }
          </Box>

          <Heading level={5} color="text" margin={{ top: "medium", bottom: "xsmall" }}>Bank</Heading>
          <Box width={{ max: "300px" }}>
            <Select
              options={banks}
              labelKey="BankName"
              valueKey={{ key: 'Id', reduce: true }}
              onChange={({ value: nextValue }) => setEft({...eft,banking_institution_id:nextValue})}
              value={eft.banking_institution_id}
            />
          </Box>

          <Heading level={5} color="text" margin={{ top: "medium", bottom: "xsmall" }}>Account Type</Heading>
          <Box width={{ max: "300px" }}>
            <Select
              options={accountTypes}
              labelKey="Name"
              valueKey={{ key: 'Id', reduce: true }}
              onChange={({ value: nextValue }) => setEft({...eft,account_type_id:nextValue})}
              value={eft.account_type_id}
            />
          </Box>

       

          <Heading level={5} color="text" margin={{ top: "medium", bottom: "xsmall" }}>Account Holder Name</Heading>
          <Box width={{ max: "300px" }}>
          <TextInput
                onChange={event => setEft({...eft,account_name:event.target.value})}
                value={eft.account_name}
              />
          </Box>

          <Heading level={5} color="text" margin={{ top: "medium", bottom: "xsmall" }}>Account Number</Heading>
          <Box width={{ max: "300px" }}>
          <TextInput
                type="number"
                step='1'
                onChange={event => setEft({...eft,account_number:event.target.value})}
                value={eft.account_number}
              />
          </Box>

          <Heading level={5} color="text" margin={{ top: "medium", bottom: "none" }}>Desired Debit Order Day</Heading>
          <Paragraph size="small" color="text-weak" margin={{ top: "none", bottom: "xsmall" }}>If your chosen day falls within the next 5 days,<br /> the 1st debit order will be in the next or following month. </Paragraph>
          <Box width={{ max: "300px" }}>
            <Select
              options={['01', '02', '03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','last day of month']}
              onChange={({ option }) => setEft({...eft,day:option})}
              value={eft.day}
            />
          </Box>

          {/* <Box margin={{ top: "medium" }}>
            <CheckBox
            
              checked={eft.debit_today}
              label="First debit order should be processed today"
              onChange={(event) => setEft({...eft,debit_today:event.target.checked})}
            />
          </Box> */}

          <Paragraph color="brand" margin={{top:"large",bottom:"xsmall"}}>Details of the bank account holder</Paragraph>


          <Heading level={5} color="text" margin={{ top: "xsmall", bottom: "xsmall" }}>Cellphone Number</Heading>
          <Box width={{ max: "300px" }}>
          <TextInput          
                type="tel"
                onChange={event => setEft({...eft,cellphone_number:event.target.value})}
                value={eft.cellphone_number}
              />
          </Box>

          <Heading level={5} color="text" margin={{ top: "medium", bottom: "xsmall" }}>Identification Type</Heading>
          <Box width={{ max: "300px" }}>
          <Select
              options={identificationTypes}
              labelKey="Name"
              valueKey={{ key: 'Id', reduce: true }}
              onChange={({ value: nextValue }) => setEft({...eft,identification_type_id:nextValue})}
              value={eft.identification_type_id}
            />
          </Box>

          <Heading level={5} color="text" margin={{ top: "medium", bottom: "xsmall" }}>Identification Number</Heading>
          <Box width={{ max: "300px" }}>
          <TextInput
                onChange={event => setEft({...eft,id_number:event.target.value})}
                value={eft.id_number}
              />
          </Box>

          
          <Heading level={5} color="text" margin={{ top: "medium", bottom: "xsmall" }}>Nationality</Heading>
          <Box width={{ max: "300px" }}>
          <Select
              options={nationalityTypes}
              labelKey="Name"
              valueKey={{ key: 'Id', reduce: true }}
              onChange={({ value: nextValue }) => setEft({...eft,nationality_id:nextValue})}
              value={eft.nationality_id}
            />
          </Box>

          <Box margin={{ top: "medium" }}>
            <CheckBox
              checked={eft.acknowledged}
              label="I accept the terms and conditions and confirm this debit order"
              onChange={(event) => setEft({...eft,acknowledged:event.target.checked})}
            />

          
              <Text color="brand" className="pointer" size="small" margin={{top:"medium"}} onClick={() => setShowTerms(!showTerms)}>
                <u>View terms and conditions</u>
              </Text>

              { showTerms && 
              <Box background="background-back" width="100%" pad="medium" className="rounded" >
              <Text size="small" >
      DebiCheck is a new type of debit order that requires you to confirm your debit order with your bank when entering into a repayment agreement with HP Attorneys. Using DebiCheck reduces the risk of an incorrect or a fraudulent debit order being collected because the debit order can only be done according to the approved agreement. <br />
      Our DebiCheck’s are processed via the Batch Mandate system.  Your bank will require that you authenticate the instruction for this transaction. This means that your bank will communicate with you or the account holder.  Approval or authentication can be given in several ways, such as using your cellphone app, your card at a branch, or Internet banking. <br />
      DebiCheck collections are only processed if the critical information matches. If it does not match, the DebiCheck collection is rejected – please ensure that all information as captured is correct and that the banking details matches the payee details.
    <br /><br />
      Please look out for your banks request to authenticate the transaction. The authentication must be completed during the next two days.
      Please note that date changes might take affect should your original payment date fall on a weekend, public holiday or during the festive season.
      
              </Text>
              </Box>

          }

          </Box>


          <Button onClick={() => submit() } primary margin={{ top: "medium", bottom: "medium" }} hoverIndicator={{ color: "status-ok" }} style={{width:'150px'}}>
            <Box margin="small" direction="row" justify="center">
              <Text color="white" weight="bold">SUBMIT</Text>
            </Box>
          </Button>

        </Box>

      }
      {loading &&
        <Box margin="medium">
          <Spinner height="64" width="64" />
        </Box>
      }





    </div>
  )
}

export default PaymentDebitOrder
