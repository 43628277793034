import React,{useContext} from 'react'
import { Avatar, Box, Image, Paragraph, Heading } from 'grommet';
import { Context as OnBoardContext } from '../../contexts/OnBoardContext'
import { Link } from 'react-router-dom'
//import { PhoneVertical } from 'grommet-icons'
import contact from '../../assets/img/contact.png'
import {ReactComponent as SearchIcon} from '../../assets/svg/SearchIcon.svg'

interface Props { }

const OnBoardingSearchNoMatch: React.FC<Props> = () => {

  const onBoardContext = useContext(OnBoardContext)
  const { user } = onBoardContext
 // const history = useHistory()


  return (
    <Box margin={{ top: "100px" }} width={{ width: "100%", max: "600px" }} alignSelf="center" className="border-desktop">

      <Avatar background="text" size="xlarge" alignSelf="center" margin={{ top: "-50px" }}>
        <SearchIcon fill="white"/>
      </Avatar>

      <Box alignSelf="center" justify="center">

        <Heading textAlign="center" size="medium" level={1} margin={{ bottom: "small" }} className="heading-h1" responsive={false}>NO MATCH</Heading>

        <Paragraph color="text-weak" margin="none" textAlign="center">
        We have no match for:
        </Paragraph>
        <Paragraph color="status-critical" margin="none" textAlign="center">
        {user.search} 
        </Paragraph>
    

        <Link  to="/">
          <Paragraph color="brand" margin={{top:"medium",bottom:"small"}} textAlign="center"><strong><u>Re-Enter Identification</u></strong></Paragraph>
        </Link>

       
        <Link to="/contact" >
          <Box alignContent="center">
            <Image

              margin={{
                left: "auto",
                right: "auto",
                top: "large",
                bottom: "-22px"
              }}
              src={contact}
            />
          </Box>

        </Link>


      </Box>

    </Box>
  )
}

export default OnBoardingSearchNoMatch
