import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Box, Paragraph, TextInput, Heading, Button, Keyboard, CheckBox } from 'grommet';
import { DocumentUser, FormNextLink } from 'grommet-icons'
import Toast, { Statuses } from '../../components/Toast'
import { Context as OnBoardContext } from '../../contexts/OnBoardContext'
import { Link, useHistory } from 'react-router-dom'
import Contact from '../Contact'
import { ReactComponent as UpdateIcon } from '../../assets/svg/UpdateIcon.svg'
import repo from '../../api/repo'
import Spinner from '../../components/Spinner'

interface Props { }

const OnBoardingUpdateNumber: React.FC<Props> = () => {

  const onBoardContext = useContext(OnBoardContext)
  const { user, updateUser } = onBoardContext
  const [error, setError] = useState({ show: false, message: '' })
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false)


  const updateNumber = async () => {
    if (!checked) {
      setError({ show: true, message: "Please confirm by ticking the tick box in order to proceed." })
      return false;
    }

    if (user.new_number == null || user.new_number.length != 10) {
      setError({ show: true, message: "Please enter 10 digit cell number." })
      return false;
    }

    setLoading(true)

    try {
   
      const { data } = await repo.updateNumber(user);
      updateUser(data.user)

      try {
        await repo.generateSmsCode(user)
        history.push('/sms-verification')
      } catch (e:any) {        
        setError({ show: true, message: e.response.data.message })        
      } finally {
        setLoading(false)
      }

    } catch (e:any) {
      if (e.response) {
        setError({ show: true, message: e.response.data.message })
      }
    } finally {
      setLoading(false)
    }

  }

  useEffect(() => {
    if (!user.id) {
      history.push('/')
    }

    return () => { }
  }, [])

  return (
    <Box margin={{ top: "100px" }} width={{ width: "100%", max: "600px" }} alignSelf="center" className="border-desktop">

      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => { setError({ show: false, message: "" }) }} />

      <Avatar background="text" size="xlarge" alignSelf="center" margin={{ top: "-50px" }}>
        <UpdateIcon fill="white" />
      </Avatar>

      {!loading &&

        <Box alignSelf="center" justify="center">

          <Heading textAlign="center" size="medium" level={1} margin={{ bottom: "small" }} className="heading-h1" responsive={false}>UPDATE NUMBER</Heading>

          <Paragraph color="text-weak" margin="none" textAlign="center" style={{ maxWidth: 300 }}>
            Please enter your 10 digit cell number
          </Paragraph>

          {/* <Paragraph color="text-weak" margin="small" textAlign="center" style={{maxWidth:300}}>
        Contact us to change your details
        </Paragraph> 

        <Contact></Contact> */}


          <Paragraph color="text-weak" size="xsmall" margin={{ top: "medium", bottom: "small" }} style={{ maxWidth: 300 }} textAlign="center" alignSelf="center">

            I hereby confirm that the account and details pertaining to the account belong to me and is not submitted by a 3rd party.  I confirm that all information as provided by myself is true and accurate.

          </Paragraph>


          <CheckBox
            checked={checked}
            label="I Confirm"
            onChange={(event) => setChecked(event.target.checked)}
          />


          <Box margin={{ top: "medium" }} direction="row">
            <Keyboard onEnter={() => { updateNumber() }}>
              <TextInput
                autoFocus
                placeholder="cell number"
                type="tel"
                onChange={event => updateUser({...user,new_number:event.target.value})}
              />
            </Keyboard>
            <Button primary margin={{ left: "small" }} onClick={() => updateNumber()}>
              <Box margin={{ left: "9px", right: "9px" }}>
                <FormNextLink size="medium" color="white" />
              </Box>

            </Button>



          </Box>




          <Box background="background-back" width="100%" pad="medium" className="rounded" margin={{
            left: "auto",
            right: "auto",
            top: "small",
            bottom: "-22px"
          }}>

            <Paragraph color="text" size="large" margin="none" textAlign="center">
              {user.name}
            </Paragraph>

            <Paragraph color="text-weak" margin="none" textAlign="center" className="l-s-3">
              {user.search}
            </Paragraph>

          </Box>

        </Box>

      }

      {loading &&
        <Box margin="medium">
          <Spinner height="64" width="64" />
        </Box>
      }

    </Box>
  )
}

export default OnBoardingUpdateNumber
