import React from 'react';
import { Grommet } from 'grommet';
import theme from './assets/style/theme'
import AppRouter from "./AppRouter";
import { BrowserRouter as Router } from 'react-router-dom'



const App = () => {


  return (
    <Grommet theme={theme as any} full={true}>
      <Router> 
          <AppRouter />
      </Router>
    </Grommet>
  );
}

export default App;
