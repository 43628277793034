import React from 'react'
import { Heading } from 'grommet'
interface Props {

}

const Terms: React.FC<Props> = () => {
  return (
    <div>
      <Heading level={1}>TERMS AND CONDITIONS</Heading>

      <h4>Your use of this Website</h4>
      <p>&nbsp;</p>
      <p>Your use of this website, and the information you find on it, is subject to the following terms and conditions and to the Copyright and Trademark Notices below. Please ensure that you thoroughly read these terms and conditions before proceeding any further. Your failure and/or refusal to read the terms and conditions in full will not be a defence to any claim or action arising as a result of these conditions nor will it be grounds for a defence or action.</p>
      <p>&nbsp;</p>
      <p>If you do not agree with all the terms and conditions, please leave the HP Attorneys website and all its pages immediately. If you proceed to enter any page or pages or this website, it will indicate your full acceptance of all the terms and conditions set out by HP Attorneys for your use of this website. If you have any queries about the terms and conditions of your use of this website, or the content of the legal notices, please exit this website and contact us using contact details on our contact page. We stress that the onus is on you to contact our offices should there be any aspect of these terms and conditions you wish to query.</p>
      <p>&nbsp;</p>
      <p>The following terms and conditions apply to your entry into or use of this website, whether on a desktop/laptop computer and/or any mobile device:</p>
      <p>&nbsp;</p>
      <p>No legal relationship as between attorney and client arises whatsoever between you and HP Attorneys through your use of this website or the information available on it. Should you seek legal advice contact our offices for a consultation where legal advice may be provided. HP Attorneys does not offer any legal advice over the internet in any manner whatsoever. No material on or generated by this website amounts to legal advice, and no relationship between an attorney and client is intended to arise out of your use of this website between you and HP Attorneys, or out of any communication you may have with HP Attorneys. Information on this website cannot be relied upon by you as legal or other professional advice or as a substitution for such advice, and all information provided on this website must be confirmed by you in consultation with relevant professionals of your choice.</p>
      <p>&nbsp;</p>
      <p>HP Attorneys in placing any opinion, analysis, interpretation, discussion, information or results on this website, does not imply or guarantee that any other person or body, whether or not in the same profession, will provide the same or similar opinion, analysis, interpretation, discussion, information or results, now or in future, and therefore any opinion, analysis, interpretation or result on this website should not be relied on or taken to be a promise or indication of a future result.</p>
      <p>&nbsp;</p>
      <h4>Confidentiality</h4>
      <p>&nbsp;</p>
      <p>Communication with HP Attorneys by e-mail over the Internet may not be secure and you should avoid sending sensitive or confidential Internet e-mail messages to HP Attorneys unless your emails are adequately encrypted. Kindly contact our offices should there be sensitive information you wish to provide.</p>
      <p>&nbsp;</p>
      <h4>Links</h4>
      <p>&nbsp;</p>
      <p>HP Attorneys makes no representations or warranties about any website that this website provides links to, or which links to this website. In addition to this HP Attorneys cannot guarantee the content of the sites that this website provides links to. The sites that this website provides links to is not under the control of HP Attorneys and as such their content is outside of the control of HP Attorneys. Other sites may link to the homepage of this website only on the prior permission of HP Attorneys. Please contact our offices in this regard.</p>
      <p>&nbsp;</p>
      <h4>Virus protection</h4>
      <p>&nbsp;</p>
      <p>HP Attorneys takes reasonable steps and makes a reasonable effort to check material on its website for viruses. However, by using the HP Attorneys website you agree that you are responsible for running an anti virus program on all material you download from the internet. Should you or your computer/mobile device be infected with a virus from the use of this website HP Attorneys accepts no responsibility and/or liability for any damage caused directly from the said virus, nor does it accept responsibility for any incidental damages arising from the said virus. By making use of this website you accept responsibility for the acquiring of any possible virus obtained through the use of this website and waive any claim you may have against HP Attorneys for damages incurred either directly or indirectly from the said virus.</p>
      <p>&nbsp;</p>
      <h4>Privacy</h4>
      <p>&nbsp;</p>
      <p>HP Attorneys does not capture or store personal information from site visitors, but reserves the right for administrative and business purposes to log a user’s IP address and session information, such as the duration of the visit to the site, and the type of web browser used, and their particular use of this website. We do not use cookies for collecting user information from the site. HP Attorneys will collect information subscribers and users provide to us through registration and/or emails, and will record transaction information. HP Attorneys will not share any information it collects about any user, except with the permission of that user or subscriber, or as required by a subscriber, subpoena, court order, operation of law, or other legal process. Note that other websites that may have links to or from this site are not covered by this privacy policy. By using this website, you signify your agreement to the terms of our privacy policy as outlined here.</p>
      <p>&nbsp;</p>
      <h4>No liability arising</h4>
      <p>&nbsp;</p>
      <p>HP Attorneys makes no representations and/or guarantees as to the accuracy of materials on this website, and you acknowledge and agree that HP Attorneys, representatives, agents, attorneys, directors, candidate attorneys and/or employees will not be liable for any loss or damages, including direct, indirect, incidental, special, consequential or exemplary damages, or damages for loss of profits, goodwill, use, data or other intangible losses, resulting from your use of this website, or any HP Attorneys product on this website. You acknowledge that HP Attorneys expressly disclaims all warranties including fitness for purpose and non-infringement. Please note that HP Attorneys does not represent that this website complies with all laws and ethical rules of any jurisdiction other than that of South Africa and if any provisions of this agreement as to the terms and conditions of your use of this website or products on it are invalid under South African law, HP Attorneys’ liability is limited to the extent permitted by that law.</p>
      <p>&nbsp;</p>
      <h4>Changes to these terms and conditions</h4>
      <p>&nbsp;</p>
      <p>HP Attorneys reserves the right to change these terms and conditions from time to time and you acknowledge and agree that you are bound by such revisions and therefore you will visit this page from time to time to review the terms and conditions of your use of this website.</p>
      <p>&nbsp;</p>
      <h4>Copyright, Trademark and other legal notices</h4>
      <p>&nbsp;</p>
      <p>The following Copyright Notice and Trademark Notice form part of these terms and conditions, and your further use of this website indicates your acceptance and agreement to these additional terms and conditions.</p>
      <p>&nbsp;</p>
      <p>Use of the website subject to terms and conditions in specific written contract between the website user and HP Attorneys: The terms and conditions on this website are subject to terms and conditions arising out of any specific contract which may have been entered into in writing between the website user and HP Attorneys.</p>
      <p>&nbsp;</p>
      <h4>Your acceptance of these terms and conditions</h4>
      <p>&nbsp;</p>
      <p>Any further use you may make of this website will be taken as indicating your acceptance of the above terms and conditions, and the requirements of users of this website as laid out in this notice of Terms and Conditions, and any Copyright, Trademark and other legal notice that is available on this website, as amended or supplemented from time to time by HP Attorneys. You therefore undertake to read such other notices along with this notice of Terms and Conditions, and to from time to time re-read these notices when you visit this website. You expressly agree that, if you are dissatisfied with these terms and conditions, or with HP Attorneys, or with information, material or products available on this website, your only remedy is to terminate these terms and conditions of use and leave this HP Attorneys website.</p>
      <p>&nbsp;</p>

      <h4>Copyright Notice</h4>
      <p>&nbsp;</p>
      <p>This website contains content and systems that are proprietary to HP Attorneys. All copyright rights in the text, images, user interface, design and other content provided on this website are owned by HP Attorneys, unless indicated otherwise or clearly part of a public document. You may not copy, reproduce, modify, distribute, display, perform or transmit any of the contents without prior permission from HP Attorneys. Note that this website organizes and reproduces text of public documents such as legislation and subordinate legislation for your research and study purposes only and you agree that you shall not use this information or material for any other purpose, or to further copy or distribute it. Intellectual property rights contained and reflected in this website belong to HP Attorneys and you shall not infringe these. If you want to copy and use any HP Attorneys’ material you shall obtain the prior approval of HP Attorneys. Please contact us for permission to copy and use any of HP Attorneys’ copyright or trademark material. Any copies made of the content of this website and any downloaded material retain the copyright and any other proprietary notices indicated on this website and/or contained in that material, and material downloaded or copied shall not be modified, edited or used out of context. Note that the absence of a copyright notice on each and every page of this website does not constitute a waiver of HP Attorneys copyright. Note also that HP Attorneys reserves all intellectual property rights in connection with all content, methods and code contained in and reflected on this website and its products on this website, unless explicitly indicated otherwise, and the content of or methodology of HP Attorneys products used on this website shall not be reproduced, in whole or part, without advance permission in writing from HP Attorneys.</p>
      <p>&nbsp;</p>

      <h4>Trademark Notice</h4>
      <p>&nbsp;</p>
      <p>All trademarks, logos, and terms of HP Attorneys that are displayed on this website, registered or unregistered, are the intellectual property of HP Attorneys and shall not be used without the prior express written permission of HP Attorneys. In particular, the term “Serious about service” is claimed as a trademark of HP Attorneys. Note that the absence of a trademark notice on each and every page of this website does not constitute a waiver of HP Attorneys trademark rights.</p>


      <p id="debicheck">&nbsp;</p>
     <h4 >Debicheck:</h4> 
     <p>
      DebiCheck is a new type of debit order that requires you to confirm your debit order with your bank when entering into a repayment agreement with HP Attorneys. Using DebiCheck reduces the risk of an incorrect or a fraudulent debit order being collected because the debit order can only be done according to the approved agreement. 
      Our DebiCheck’s are processed via the Batch Mandate system.  Your bank will require that you authenticate the instruction for this transaction. This means that your bank will communicate with you or the account holder.  Approval or authentication can be given in several ways, such as using your cellphone app, your card at a branch, or Internet banking.
      DebiCheck collections are only processed if the critical information matches. If it does not match, the DebiCheck collection is rejected – please ensure that all information as captured is correct and that the banking details matches the payee details.
      <br /><br />
      Please look out for your banks request to authenticate the transaction. The authentication must be completed during the next two days.
      Please note that date changes might take affect should your original payment date fall on a weekend, public holiday or during the festive season.
      </p>

    </div>

  )
}

export default Terms
