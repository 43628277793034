import React, { useEffect, useState } from 'react'
import { Box, Heading, Paragraph, Text, ResponsiveContext } from 'grommet';
import { FormPreviousLink, StatusGood } from 'grommet-icons'
import { Link } from 'react-router-dom'
import { useParams } from "react-router-dom";
import Spinner from '../../components/Spinner'
import repo from '../../api/repo'
import Toast, { Statuses } from '../../components/Toast'
import Moment from 'react-moment';
import Survey from './../Survey';
interface Props { }

const PaymentSuccess: React.FC<Props> = () => {

  let size = React.useContext(ResponsiveContext);
  if (size !== 'small') {
    size = 'medium'
  }


  const [loading, setLoading] = useState(false)
  let { id, payment_id } = useParams();
  const [payment, setPayment] = useState<any>({});
  const [account, setAccount] = useState<any>({});
  const [paymentFound, setPaymentFound] = useState(false);
  const [accountFound, setAccountFound] = useState(false);
  const [error, setError] = useState({ show: false, message: '' })


  useEffect(() => {

    async function fetchPayment() {
      setLoading(true);
      try {
        const { data } = await repo.getPayment(payment_id, id);
        setPayment(data.payment);
        setAccount(data.account)
        setPaymentFound(true);
        setAccountFound(true);

      
      } catch (e: any) {
        if (e.response) {
          setError({ show: true, message: e.response.data.message })
        }
      } finally {
        setLoading(false)
      }

    }
    fetchPayment()


    return () => { }
  }, [])


  

 

  return (
    <div>

      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => { setError({ show: false, message: "" }) }} />

      {accountFound &&
        <Box border={true} pad="small" direction="row" style={{ flexWrap: "wrap" }} className="rounded">


          <Link to="/app/accounts">
            <Text color="brand" size="small">
              <u>ACCOUNTS</u> &nbsp;
            </Text>
          </Link>

          <Link to={"/app/accounts/" + id + "/account"} >
            <Text color="brand" size="small" >
              / <u>{account.Matter.ClientSmsName.toUpperCase()}</u>&nbsp;
            </Text>
          </Link>

          <Link to={"/app/accounts/" + id + "/payment-options"}>
            <Text color="brand" size="small" >
              / <u>PAYMENTS</u> &nbsp;
            </Text>
          </Link>




          <Text color="text-weak" size="small" style={{ marginTop: "2px" }} >
            / RESULT
          </Text>


        </Box>
      }

      {paymentFound && !loading &&



        <Box>

              {payment.api_update_success == 1 &&
                <Survey surveyType="payment-success"/>
              }

          <Box direction="row">
            <Box flex={{ grow: 1 }} align="start">
              <Heading level={1} margin={{ top: "large", bottom: "none" }}>PAYMENT</Heading>
              <Box background="text" margin={{ top: "xsmall" }} className="rounded" pad="small" direction="row">
                <Text color="white">AC# {id}</Text>
              </Box>
            </Box>
          </Box>


          <Box direction="row" margin={{ top: "large" }}>



            <Box direction="column">
            

              {payment.type == 'Immediate' &&

                <Box>
                  <StatusGood color="status-ok" size="large"></StatusGood>

                  <Heading level={4} color="text" margin={{ top: "small", bottom: "small" }}>Thank You!</Heading>


                  <Paragraph color="text-grey" margin="none">
                    Your  payment
                    for R {payment.amount}  has been received.
                  </Paragraph>

                </Box>
              }

              {payment.type == 'Debit Order' && payment.api_update_success == 1  &&

                <Box>
                  <StatusGood color="status-ok" size="large"></StatusGood>

                  <Heading level={4} color="text" margin={{ top: "small", bottom: "small" }}>Thank You!</Heading>

                  <Paragraph color="text-grey" margin="none">
                    Your  debit order has been setup for R {payment.amount} , and your first debit order is scheduled for : &nbsp;
                    <Moment format="DD MMM YYYY">
                      {account.Matter.NextPaymentDate}
                    </Moment>
                    {/* for { Math.floor(account.Matter.MatterCurrentOutstandingBalance / payment.amount) }   {payment.frequency} payments of  R {payment.amount}
                  {account.Matter.MatterCurrentOutstandingBalance % payment.amount > 0 ? " and a final payment of R" + (account.Matter.MatterCurrentOutstandingBalance % payment.amount).toFixed(2) : "" } */}
                  </Paragraph>

                </Box>
              }

              {payment.type == 'Debit Order' && payment.api_update_success == 0 &&
                <Paragraph color="text-grey" margin="none">
                  Whoops something went wrong, Your  debit order payment plan has <strong> NOT has been setup.</strong>
                </Paragraph>
              }


              

              <Link to="/app/accounts">
                <Paragraph color="brand" margin={{ top: "medium", bottom: "none" }}><strong><u>My Accounts</u></strong></Paragraph>
              </Link>


              <Box background="background-back" width="100%" pad="medium" className="rounded" justify="center" direction="row" margin={{
                left: "auto",
                right: "auto",
                top: "xlarge",
              }}>
                <Paragraph color="status-critical" size="medium" textAlign="center">
                  <strong>Balances on this portal are not updated in realtime and successful payments made could take around 4 working days to reflect.</strong>
                </Paragraph>



              </Box>

            </Box>
          </Box>

        </Box>

      }
      {loading &&
        <Box margin="medium">
          <Spinner height="64" width="64" />
        </Box>
      }



    </div>
  )
}

export default PaymentSuccess
