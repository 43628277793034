import React from 'react'
import { Heading, ResponsiveContext } from 'grommet';


interface Props { }

const Profile: React.FC<Props> = () => {

  let size = React.useContext(ResponsiveContext);
  if (size !== 'small') {
    size = 'medium'
  }




  return (
    <div>
      <Heading level={1}>PROFILE</Heading>


    </div>
  )
}

export default Profile
