import React, { useContext, useEffect, useState } from 'react'
import { Box, Heading, Paragraph, Text, ResponsiveContext } from 'grommet';
import { User as UserIcon, FormNextLink } from 'grommet-icons'
import Circle from '../../components/style/Circle'
import theme from '../../assets/style/theme'
import { Context as UserContext } from '../../contexts/UserContext'
import { useHistory } from "react-router-dom";
import Spinner from '../../components/Spinner'
import repo from '../../api/repo'
import NumberFormat from 'react-number-format';
import Toast, { Statuses } from '../../components/Toast'

interface Props { }

const Accounts: React.FC<Props> = () => {

  let size = React.useContext(ResponsiveContext);
  if (size !== 'small') {
    size = 'medium'
  }

  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [unpaidAccounts, setUnpaidAccounts] = useState(0);

  const userContext = useContext(UserContext)
  const [accounts, setAccounts] = useState<any[]>([]);
  const [error, setError] = useState({ show: false, message: '' })

  useEffect(() => {

    async function fetchAccounts() {
      setLoading(true);
      try {
        const { data } = await repo.accounts();
        setAccounts([...data.accounts]);
      } catch (e: any) {
        if (e.response) {
          setError({ show: true, message: e.response.data.message })
        }
      } finally {
        setLoading(false)
      }

    }

    fetchAccounts()


    return () => { }
  }, [])


  useEffect(() => {
    setUnpaidAccounts(0);
    var count = 0;
    accounts.forEach(ac => {
      if (parseFloat(ac.Matter.MatterCurrentOutstandingBalance) > 0) {
        count++;
      }

    })
    setUnpaidAccounts(count);
  }, [accounts])



  return (
    <div>
      <h1>ACCOUNTS</h1>


      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => { setError({ show: false, message: "" }) }} />

      {!loading &&


        <Box>

          {/* <Box direction="row" margin={{ top: "medium" }}>
            <Box margin={{ right: "medium" }}>
              <UserIcon color="text-weak"></UserIcon>
            </Box>
            <Box>
              <Paragraph color="text" size="large" margin={{ top: 'none', bottom: 'small' }}>
                <strong>{userContext.user.name}</strong>
              </Paragraph>

            </Box>
          </Box> */}


          <Box background="text" width="100%" pad="medium" direction="row" justify="center" className="rounded" margin={{
            left: "auto",
            right: "auto",
            top: "medium",
            bottom: "medium"
          }}>
            <Paragraph color="white" margin="none" size="large" textAlign="center">
              You have {unpaidAccounts} accounts that have outstanding balances
            </Paragraph>


          </Box>

          {accounts.map((ac, index) => (

            <Box
              key={index}
              onClick={() => history.push(`/app/accounts/${ac.MatterID}/account`)}
              className="pointer rounded"
              background="white"
              border={true}
              width="100%"
              pad="medium"
              justify="center"
              direction="row"
              margin={{
                left: "auto",
                right: "auto",
                top: "small",
                bottom: "small",
              }}>

              <Box>


                {ac.Matter.MatterCurrentOutstandingBalance > 0 && !ac.Matter.ActivePlan &&
                  <Circle width="25px" height="25px" color={theme.global.colors['status-critical']}></Circle>
                }
                {ac.Matter.MatterCurrentOutstandingBalance <= 0 &&
                  <Circle width="25px" height="25px" color={theme.global.colors['status-ok']}></Circle>
                }

                {ac.Matter.MatterCurrentOutstandingBalance > 0 && ac.Matter.ActivePlan &&
                  <Circle width="25px" height="25px" color={theme.global.colors['lightBlue']}></Circle>
                }



              </Box>

              <Box flex={{ grow: 1 }} margin={{ left: size === "small" ? "medium" : "xlarge" }}>
                <Heading level={3} margin={{ top: "none", bottom: "xsmall" }}> {ac.Matter.ClientSmsName} </Heading>
                <Text color="text-weak">AC# {ac.MatterID}</Text>


                <NumberFormat
                  value={ac.Matter.MatterCurrentOutstandingBalance}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'R '}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  renderText={formattedValue => <Heading level={3} className="font-weight-normal" margin={{ bottom: "none" }}>{formattedValue}</Heading>}
                />


              </Box>

              <Box>
                <FormNextLink color="text" size={size === "small" ? "medium" : "large"} ></FormNextLink>
              </Box>

            </Box>
          ))}

        </Box>

      }
      {loading &&
        <Box margin="medium">
          <Spinner height="64" width="64" />
        </Box>
      }





      <Box background="background-back" width="100%" pad="medium" className="rounded" justify="center" direction="row" margin={{
        left: "auto",
        right: "auto",
        top: "medium",
      }}>
        <Paragraph color="text-weak" size="medium" textAlign="center">
          Disclaimer notice: The balances provided on the
          portal are for informational purposes, actual
          balances and settlements to be confirmed with an agent. Payments made are not updated in realtime and will take some time to reflect.
        </Paragraph>



      </Box>

      <Box direction="row" margin={{top:"medium"}} justify="evenly" style={{ flexWrap: "wrap" }}>



        <Box direction="row" justify="start" margin="small" pad="medium" border className="rounded">
          <Circle width="20px" height="20px" color={theme.global.colors['status-ok']}></Circle>&nbsp;<Text color="primary"> = Paid Up </Text>
        </Box>

        <Box direction="row" justify="start" margin="small" pad="medium" border className="rounded">
          <Circle width="20px" height="20px" color={theme.global.colors['lightBlue']}></Circle>&nbsp;<Text color="primary"> = Active Payment plan</Text>
        </Box>

        <Box direction="row" justify="start" margin="small" pad="medium" border className="rounded">
          <Circle width="20px" height="20px" color={theme.global.colors['status-critical']}></Circle>&nbsp;<Text color="primary"> = No active payment plan </Text>
        </Box>


      </Box>

    </div>
  )
}

export default Accounts
