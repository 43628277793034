import React,{useState} from 'react'
import { Box, Text, TextInput, ResponsiveContext,Button, Heading } from 'grommet';
import { FormNext,FormNextLink } from 'grommet-icons';
import { useHistory } from "react-router-dom";
import {ReactComponent as BlueCircleCallUsIcon} from '../assets/svg/BlueCircleCallUsIcon.svg'
import {ReactComponent as BlueCircleMailIcon} from '../assets/svg/BlueCircleMailIcon.svg'
import {ReactComponent as BlueCircleWhatsappIcon} from '../assets/svg/BlueCircleWhatsappIcon.svg'
import {ReactComponent as BlueCircleTelegramIcon} from '../assets/svg/BlueCircleTelegramIcon.svg'
//import {ReactComponent as BlueCircleChatIcon} from '../assets/svg/BlueCircleChatIcon.svg'
import {ReactComponent as BlueCircleCallIcon} from '../assets/svg/BlueCircleCallIcon.svg'
import Toast, { Statuses } from '../components/Toast'
import Spinner from '../components/Spinner'
import repo from '../api/repo'

interface Props {}

const Contact: React.FC<Props> = () => {

  const [clientNum, setClientNum] = React.useState('');

  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ show: false, message: '' })
  const [success, setSuccess] = useState({ show: false, message: '' })

  const goTo = (url) => {
    history.push(url)
  }

  const callMe = async () => {
    if(clientNum===''){     
      setError({ show: true, message: "Please enter contact number" })      
      return false;
    }

    setLoading(true)
    try{
      await repo.callMe({phone_number:clientNum})
      setSuccess({show:true,message:"We have received your request and will be in touch with you ASAP."})
    }catch(e){
      setError({ show: true, message: "Something went wrong" })      
    }finally{
      setLoading(false)
    }
  }


  let size = React.useContext(ResponsiveContext);
  if (size !== 'small') {
    size = 'medium'
  }


  return (
    <div>
      <Heading level={1}>CONTACT</Heading>

      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => {setError({ show: false, message: "" })}}/>
      <Toast status={Statuses.success} message={success.message} show={success.show} onClose={() => {setSuccess({ show: false, message: "" })}}/>

      {/* Chat */}
      {/* <Box direction="column" gap="x-small" align="start" border="bottom" pad={{ bottom: "small" }} onClick={() => goTo("/")} className="pointer" >
        <Box direction="row" margin={{ top: "medium" }} width="100%">
          <Box margin={{ right: "medium" }}>
            <BlueCircleChatIcon width="44"/>
          </Box>
          <Box >
            <Box pad="none">
              <Text weight="bold" size="medium" color="text" >
                Chat with an operator
                </Text>
              <Text margin="none" color="text-weak" size="small" >
                Start a live chat with an agent
              </Text>
            </Box>
          </Box>
          <Box flex={{ grow: 1 }} direction="row" justify="end">
            <Box >
              <FormNext size="medium" color="brand" />
            </Box>
          </Box>
        </Box>
      </Box> */}

       {/* Whatsapp */}
       <Box direction="column" gap="x-small" align="start" border="bottom" pad={{ bottom: "small" }} onClick={() => window.location.assign("https://bit.ly/30xScMn")} className="pointer" >
        <Box direction="row" margin={{ top: "medium" }} width="100%">
          <Box margin={{ right: "medium" }}>
          <BlueCircleWhatsappIcon width="44"/>
          </Box>
          <Box >
            <Box pad="none">
              <Text weight="bold" size="medium" color="text" >
              Start a WhatsApp chat
                </Text>
              <Text margin="none" color="text-weak" size="small" >
                Chat with and agent on Whatsapp
              </Text>
            </Box>
          </Box>
          <Box flex={{ grow: 1 }} direction="row" justify="end">
            <Box >
              <FormNext size="medium" color="brand" />
            </Box>
          </Box>
        </Box>
      </Box>

          {/* Telegram */}
          <Box direction="column" gap="x-small" align="start" border="bottom" pad={{ bottom: "small" }} onClick={() => window.location.assign("https://t.me/HammondPoleAttorneysBot")} className="pointer" >
        <Box direction="row" margin={{ top: "medium" }} width="100%">
          <Box margin={{ right: "medium" }}>
          <BlueCircleTelegramIcon width="44"/>
          </Box>
          <Box >
            <Box pad="none">
              <Text weight="bold" size="medium" color="text" >
              Start a Telegram chat
                </Text>
              <Text margin="none" color="text-weak" size="small" >
                Chat with and agent on telegram
              </Text>
            </Box>
          </Box>
          <Box flex={{ grow: 1 }} direction="row" justify="end">
            <Box >
              <FormNext size="medium" color="brand" />
            </Box>
          </Box>
        </Box>
      </Box>


       {/* Call back */}
       <Box direction="column" gap="x-small" align="start" border="bottom" pad={{ bottom: "small" }} >
        <Box direction="row" margin={{ top: "medium" }} width="100%">
          <Box margin={{ right: "medium" }}>
          <BlueCircleCallIcon width="44"/>
          </Box>
          <Box >
            <Box pad="none">
              <Text weight="bold" size="medium" color="text" >
              Call me back
                </Text>
                <Box margin={{top:"small"}} direction="row">
                  <TextInput
                    placeholder="contact number"
                    value={clientNum} 
                    onChange={event => setClientNum(event.target.value)}
                  />
                  {!loading &&
                  <Button primary margin={{left:"small"}} onClick={ () => callMe()}>
                    <Box margin={{left:"9px",right:"9px"}}>
                      <FormNextLink size="medium" color="white"  />
                    </Box>
                   
                  </Button>
                  }
                  {loading &&
                  <Spinner/>
                  }
                </Box>
            </Box>
          </Box>
          
        </Box>
      </Box>


       {/* Email */}
       <Box direction="column" gap="x-small" align="start" border="bottom" pad={{ bottom: "small" }} onClick={() => goTo("/")} className="pointer" >
        <Box direction="row" margin={{ top: "medium" }} width="100%">
          <Box margin={{ right: "medium" }}>
          <BlueCircleMailIcon width="44"/>
          </Box>
          <Box >
            <Box pad="none">
              <Text weight="bold" size="medium" color="text" >
              Send us an email
                </Text>
                <Text margin="none" color="text-weak" size="small" >
              Send your enquiries to:
              </Text>
              <a href="mailto:virtual@hpd.co.za" className="col-brand font-weight-bold">virtual@hpd.co.za</a>
            </Box>
          </Box>
          
        </Box>
      </Box>


       {/* Phone Us */}
       <Box direction="column" gap="x-small" align="start" border="bottom" pad={{ bottom: "small" }} >
        <Box direction="row" margin={{ top: "medium" }} width="100%">
          <Box margin={{ right: "medium" }}>
          <BlueCircleCallUsIcon width="44"/>
          </Box>
          <Box >
            <Box pad="none">
              <Text weight="bold" size="medium" color="text" >
              Phone us
                </Text>
              <Text margin="none" color="text-weak" size="small" >
              Our office hours are Mon- Fri 7:30am to 4:30PM.
              </Text>
              <a href="tel:0105921873" className="col-brand font-weight-bold">010 592 1873</a>
            </Box>
          </Box>
          
        </Box>
      </Box>


    </div>
  )
}

export default Contact
