import { Heading } from 'grommet'
import React from 'react'

interface Props {

}

const Privacy: React.FC<Props> = () => {
  return (
    <div>
      <Heading level={1}>PRIVACY POLICY</Heading>

      <p >We at HP Attorneys respect your right to privacy.</p>
      <p >When referred to “Personal Information”, it means “Personal Information” as defined in the Protection of Personal Information Act, 4 of 2013 (“POPI”) and ‘Personal data” as per the General Date Protection Regulation 2016/679 (the “GDPR”).&nbsp;</p>
      <p >Personal Information includes any information about a personal that can be used to identify a natural person, directly or indirectly.&nbsp;</p>
      <p >It includes information such as a name, identification number, location information, an online identifier or one (or more) factors specific to the physical identity of a person.&nbsp;</p>
      <p >“POPI” includes the personal information of a juristic person in its ambit – so personal information of a juristic person will be protected in the same manner as any other personal information.</p>
      <p >Where applicable, this privacy policy applies to any agreement that you entered with us, including letters of engagement.</p>
      <p >For any queries relating to this policy please contact us on Info@hammondpole.co.za.</p>
      <p ><strong>Personal Information Collected, but not limited to:</strong></p>
      <ul >
        <li>personal details, such as your name, email address or telephone number;</li>
        <li>billing information, such as relevant payment information; and</li>
        <li>legally required information, which includes any additional information that the law requires from us to verify your identity.</li>
      </ul>
      <p ><strong>Method of Collections</strong></p>
      <ul >
        <li>Directly from you when you use our services or provide us with same via our website/portal facilities.</li>
        <li>In some or more instances, we collect personal information from third parties. We will only collect personal information this way where such information is publicly available or for legitimate business purposes.</li>
      </ul>
      <p ><strong>Use of personal information:</strong></p>
      <p >We may use your personal information for any legitimate business purposes relating to our services and/or business activities.</p>
      <p >Some of the purposes for which we use your personal information include:</p>
      <ul >
        <li>Responding to your queries;</li>
        <li>Onboarding you as a client</li>
        <li>Providing you with our services</li>
        <li>complying with our regulatory or other obligations</li>
        <li>Complying with the requirements of our clients as set out in the applicable Service Level Agreements,</li>
      </ul>
      <p ><strong>Sharing personal information:</strong></p>
      <p >We will only share personal information for purposes of providing a service to you relating to our business activities, including but not limited to, the protection of our or your rights, complaints or enforcing any agreement between us.</p>
      <p >Where required for business operations, we may share your personal information with our service provides – information will only be shared with service providers after we have entered into a legitimate agreement with said provider to regulate the way in which personal information will be secured.</p>
      <p >Where Law requires us to do so, we may also share personal information with a third-party service provider, agent, contractor, employees or business affiliates.&nbsp; Personal information will only be shared in these instances where it is necessary for us to do so and only to the extend that your personal information is needed for such third parties to perform services or obligations.</p>
      <p ><strong>Security:</strong></p>
      <p >All reasonable steps will be taken to ensure that personal information is protected.&nbsp; We protect and manage personal information by using electronic and compute safeguards such as Firewalls, encryption and physical/electronic access controls to our premises.</p>
      <p >Access to personal information will only be authorised to those employees who require it to fulfil their nominated responsibilities.</p>
      <p ><strong>Right to object:</strong></p>
      <p >You may, on reasonable grounds, object to us using your personal information. If you object, we will stop using your personal information, except if the law allows its use.</p>
      <p ><strong>Complaints:</strong></p>
      <p >If you believe we are using your personal information unlawfully, please let us know first (on&nbsp;<span ><a href="mailto:POPIA@hammondpole.co.za">POPIA@hammondpole.co.za</a></span> – ATTENTION:&nbsp; Information Officer).</p>
      <p >You may lodge a complaint to the Information Regulator (South Africa) with the following contact details:</p>
      <ul >
        <li>Website:&nbsp; www.justice.gov.za/inforeg/contact.html</li>
        <li>Address: 33 Hoofd Street, Forum 3, 3<sup>rd</sup> Floor, Braampark</li>
        <li>Contact number: 010&nbsp;023 5200</li>
        <li>Email:&nbsp;<span ><a href="mailto:inforeg@justice.gov.za">inforeg@justice.gov.za</a>.</span></li>
      </ul>
      <p ><strong>Quality and Access to personal information:</strong></p>
      <p >Enabling us to ensure that your personal information is accurate and up to date, you may ask us to correct any personal information that you think is inaccurate or request us to remove your information by sending an email to POPIA@hammondpole.co.za.</p>
      <p >You have the right to request us to provide you with the personal information we hold on record about you.</p>
      <p >You must contact us directly to do so.</p>
      <p ><strong>Retention of Information:</strong></p>
      <p >We retain personal information in accordance with the mandatory retention periods in law or for legitimate business purposes or as governed by client SLA’s.&nbsp; The retention of information is categorised as follows:</p>
      <ul >
        <li>Retention required by Law;</li>
        <li>Retention as reasonably required;</li>
        <li>Retention determined by Contacts/SLA;</li>
        <li>Retention as per Consent;</li>
      </ul>
      <p >This privacy policy also applies when we retain your personal information.</p>
      <p ><strong>Security breach:</strong></p>
      <p >We will report any security breach to the Information Regulator and to the individuals/ companies involved.</p>
      <p >If you want to report any concerns about our privacy practices or if you suspect any breach regarding your personal information, kindly notify us by sending an email to&nbsp;</p>
      <p ><a href="mailto:POPIA@hammondpole.co.za"><span >POPIA@hammondpole.co.za</span></a> – ATTENTION:&nbsp; Information Officer</p>

    </div>
  )
}

export default Privacy
