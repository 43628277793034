import React, { useState, useEffect, useContext } from 'react'
import { Box, Heading, Paragraph, Text, ResponsiveContext, Tabs, Button } from 'grommet';
import Circle from '../../components/style/Circle'
import theme from '../../assets/style/theme'
import { Link } from 'react-router-dom'
import ButtonTab from '../../components/style/ButtonTab'
import { useParams } from "react-router-dom";
import Spinner from '../../components/Spinner'
import repo from '../../api/repo'
import NumberFormat from 'react-number-format';
import Moment from 'react-moment';
import Toast, { Statuses } from '../../components/Toast'
import { Context as AccountsContext } from '../../contexts/AccountsContext'
import { useHistory } from "react-router-dom";
import moment from 'moment';

interface Props { }

const Account: React.FC<Props> = () => {

  let size = React.useContext(ResponsiveContext);
  if (size !== 'small') {
    size = 'medium'
  }


  const [loading, setLoading] = useState(false)
  let { id } = useParams();
  const [account, setAccount] = useState<any>({});
  const [accountFound, setAccountFound] = useState(false);
  const [error, setError] = useState({ show: false, message: '' })


  const accountsContext = useContext(AccountsContext)
  const { accounts, updateAccounts } = accountsContext
  const history = useHistory();

  useEffect(() => {

    async function fetchAccount() {
      setLoading(true);
      try {
        const { data } = await repo.account(id);

        if (moment(data.account.Matter.AodDate).isBefore(moment(), 'day')) {
          setLoading(false)
          history.push(`/app/accounts/${id}/acknowledge`)
        } else {
          setAccount(data.account);
          setAccountFound(true);
          setLoading(false)
        }


      } catch (e: any) {
        setLoading(false)
        if (e.response) {
          setError({ show: true, message: e.response.data.message })
        }
      }

    }

    fetchAccount()

    return () => { }
  }, [])

  return (
    <div>

      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => { setError({ show: false, message: "" }) }} />

      {accountFound &&
        <Box border={true} pad="small" direction="row" className="rounded" style={{ flexWrap: "wrap" }} >

          <Link to="/app/accounts">
            <Text color="brand" size="small">
              <u>ACCOUNTS</u>
            </Text>
          </Link>

          <Text color="text-weak" size="small" style={{ marginTop: "2px" }}>
            &nbsp; / {account.Matter.ClientSmsName}
          </Text>


        </Box>
      }





      {!loading && accountFound &&
        <Box>
          <Box direction="row">
            <Box flex={{ grow: 9 }} align="start">
              <Heading level={1} margin={{ top: "large", bottom: "none" }}>{account.Matter.ClientSmsName}</Heading>
              <Box background="text" margin={{ top: "xsmall" }} className="rounded" pad="small" direction="row">
                <Text color="white">AC# {account.MatterID}</Text>
              </Box>
            </Box>
            <Box flex={{ grow: 1 }} justify="end" alignSelf="center" direction="row">

              {account.Matter.MatterCurrentOutstandingBalance > 0 && !account.Matter.ActivePlan &&
                <Circle width="25px" height="25px" color={theme.global.colors['status-critical']}></Circle>
              }
              {account.Matter.MatterCurrentOutstandingBalance <= 0 &&
                <Circle width="25px" height="25px" color={theme.global.colors['status-ok']}></Circle>
              }

              {account.Matter.MatterCurrentOutstandingBalance > 0 && account.Matter.ActivePlan &&
                <Circle width="25px" height="25px" color={theme.global.colors['lightBlue']}></Circle>
              }

            </Box>
          </Box>


          <Tabs justify="start" alignControls="start" margin={{ top: "large" }} >

            <ButtonTab title="Balances">

              <Box pad="none" margin={{ bottom: "small", top: "medium" }} direction="row">
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph color="text-grey" margin="none">Outstanding</Paragraph>
                </Box>
                <Box flex={{ grow: 1 }} pad="none">
                  <NumberFormat
                    value={account.Matter.MatterCurrentOutstandingBalance}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'R '}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={formattedValue => <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{formattedValue}</Paragraph>}
                  />
                </Box>
              </Box>

              <Box pad="none" margin={{ bottom: "small" }} direction="row">
                <Box flex={{ grow: 1 }}>
                  <Paragraph color="text-grey" margin="none">Capital Amount</Paragraph>
                </Box>
                <Box flex={{ grow: 1 }}>
                  <NumberFormat
                    value={account.Matter.CapitalAmount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'R '}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={formattedValue => <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{formattedValue}</Paragraph>}
                  />
                </Box>
              </Box>



              <Box pad="none" margin={{ bottom: "small" }} direction="row">
                <Box flex={{ grow: 1 }}>
                  <Paragraph color="text-grey" margin="none">Minimum Payment</Paragraph>
                </Box>
                <Box flex={{ grow: 1 }}>
                  <NumberFormat
                    value={account.Matter.MinimumPayment}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'R '}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={formattedValue => <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{formattedValue}</Paragraph>}
                  />
                </Box>
              </Box>

              {/* {account.Matter.MatterCurrentOutstandingBalance > 0 && account.Matter.SettlementDiscount > 0 &&
                <Box pad="none" margin="none" direction="column">
                  <Box pad="none" margin="none" direction="row">
                    <Box flex={{ grow: 1 }}>
                      <Paragraph color="text-grey" margin="none">Settlement Discount</Paragraph>
                    </Box>
                    <Box flex={{ grow: 1 }}>

                      <NumberFormat
                        value={account.Matter.MatterCurrentOutstandingBalance - account.Matter.SettlementDiscount}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'R '}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        renderText={formattedValue => <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{formattedValue}</Paragraph>}
                      />

                    </Box>
                  </Box>

                  <Paragraph color="text-grey" margin="none" size="small">This is the discounted amount to pay if you <strong>settle your account in full.</strong></Paragraph>

                </Box>

              } */}

              {account.Matter.MatterCurrentOutstandingBalance > 0 && account.Matter.SettlementDiscount == 0 &&
                <Box pad="none" margin={{ bottom: "none" }} direction="row">
                  <Paragraph margin="none">
                    Settle your account in full and receive a possible discount, contact an agent to
                    get started.
                  </Paragraph>
                </Box>
              }

              <Link to="/contact">
                <Paragraph color="brand" margin={{ top: "none", bottom: "none" }}><strong><u>Contact Us</u></strong></Paragraph>
              </Link>

            </ButtonTab>



            <ButtonTab title="Payments">

              <Box pad="none" margin={{ bottom: "small", top: "medium" }} direction="row">
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph color="text-grey" margin="none">Last Payment Amount</Paragraph>
                </Box>
                <Box flex={{ grow: 1 }} pad="none">
                  <NumberFormat
                    value={account.Matter.LastPaymentAmount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'R '}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={formattedValue => <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{formattedValue}</Paragraph>}
                  />
                </Box>
              </Box>

              <Box pad="none" margin={{ bottom: "small" }} direction="row">
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph color="text-grey" margin="none">Last Payment Date</Paragraph>
                </Box>
                <Box flex={{ grow: 1 }} pad="none">

                  {account.Matter.LastPaymentDate != "1900-01-01T00:00:00" && account.Matter.LastPaymentDate != null ?
                    <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">
                      <Moment format="DD MMM YYYY">
                        {account.Matter.LastPaymentDate}
                      </Moment>
                    </Paragraph>
                    :
                    <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">
                      ...
                    </Paragraph>
                  }

                </Box>
              </Box>

              {account.Matter.NextPaymentDate != null && moment(account.Matter.NextPaymentDate.substr(0, 10)).isValid() && (moment(account.Matter.NextPaymentDate.substr(0, 10) + "T23:59:59") >= moment()) &&
                <Box>

                  <Paragraph color="brand" >Current Payment Plan</Paragraph>


                  <Box pad="none" margin={{ bottom: "small" }} direction="row">
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph color="text-grey" margin="none">Payment Plan Frequency</Paragraph>
                    </Box>
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{account.Matter.PaymentPlanFrequency}</Paragraph>
                    </Box>
                  </Box>

                  <Box pad="none" margin={{ bottom: "small" }} direction="row">
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph color="text-grey" margin="none">Next Payment Amount</Paragraph>
                    </Box>
                    <Box flex={{ grow: 1 }} pad="none">
                      {account.Matter.NextPaymentAmount < 0 ?
                        <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">***</Paragraph>
                        :
                        <NumberFormat
                          value={account.Matter.NextPaymentAmount}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'R '}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          renderText={formattedValue => <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{formattedValue}</Paragraph>}
                        />
                      }
                    </Box>
                  </Box>

                  <Box pad="none" margin={{ bottom: "small" }} direction="row">
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph color="text-grey" margin="none">Next Payment Date</Paragraph>
                    </Box>
                    <Box flex={{ grow: 1 }} pad="none">
                      {account.Matter.NextPaymentDate > 0 ?
                        <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">***</Paragraph>
                        :

                        <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">
                          <Moment format="DD MMM YYYY">
                            {account.Matter.NextPaymentDate}
                          </Moment>
                        </Paragraph>
                      }
                    </Box>
                  </Box>

                  <Box pad="none" margin={{ bottom: "small" }} direction="row">
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph color="text-grey" margin="none">Payment Method</Paragraph>
                    </Box>
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{account.Matter.PaymentMethod}</Paragraph>
                    </Box>
                  </Box>
                </Box>
              }

            </ButtonTab>

            <ButtonTab title="Accounts" style={{ width: "100%" }}>

              <Box pad="none" margin={{ bottom: "small", top: "medium" }} direction="row">
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph color="text-grey" margin="none">Account Number</Paragraph>
                </Box>
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{account.Matter.AccountNumber}</Paragraph>
                </Box>
              </Box>

              <Box pad="none" margin={{ bottom: "small" }} direction="row">
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph color="text-grey" margin="none">Reference</Paragraph>
                </Box>
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{account.MatterID}</Paragraph>
                </Box>
              </Box>



              {/* <Box pad="none" margin={{ bottom: "small" }} direction="row">
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph color="text-grey" margin="none">Juristic Status</Paragraph>
                </Box>
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{account.JurisdictionStatus}</Paragraph>
                </Box>
              </Box> */}

            </ButtonTab>


          </Tabs>


          {account.Matter.MatterCurrentOutstandingBalance > 0 && account.Matter.Status != 'On Hold' &&
            <Link to={'/app/accounts/' + account.MatterID + '/payment-options'}>
              <Button primary margin={{ top: "large" }}>
                <Box margin="small" direction="row" justify="center">
                  <Text color="white" weight="bold">ARRANGE PAYMENT</Text>
                </Box>
              </Button>
            </Link>
          }

          {account.Matter.MatterCurrentOutstandingBalance > 0 && account.Matter.Status == 'On Hold' &&
            <Link to={'/contact'} >
              <Box background="text" width="100%" pad="medium" className="rounded" justify="center" direction="column"  margin={{
                left: "auto",
                right: "auto",
                top: "large",
              }}>
                <Paragraph color="white" style={{width:"100%"}} >
                  Your account has been placed on hold, and you cannot currently complete a payment arrangement on this portal. 
                  <br />
                  <strong>This in no way means that your account is closed and you are still liable for the outstanding balance.</strong> 
                  <br />
                  In order to continue please make contact with us so that we can correct your account status.
                </Paragraph>
                <Button primary margin={{ top: "small" }} style={{width:'150px'}}>
                  <Box margin="small" direction="row" justify="center" >
                    <Text color="white" weight="bold">CONTACT US</Text>
                  </Box>
                </Button>
              </Box>

            </Link>
          }




        </Box>
      }
      {loading &&
        <Box margin="large">
          <Spinner height="64" width="64" />
        </Box>
      }

      <Box background="background-back" width="100%" pad="medium" className="rounded" justify="center" direction="row" margin={{
        left: "auto",
        right: "auto",
        top: "xlarge",
      }}>
        <Paragraph color="text-weak" size="medium" textAlign="center">
          Disclaimer notice: The balances provided on the
          portal are for informational purposes, actual
          balances and settlements to be confirmed with
          an agent. Please note that the arrangement
          captured will be reviewed within 6 months of first
          payment.
        </Paragraph>

      </Box>


    </div>
  )
}

export default Account
