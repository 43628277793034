import React from 'react'
import { Box } from 'grommet';

interface Props { 
  width:string,
  height:string,
  color:string,
}

const Circle: React.FC<Props> = ({width,height,color}) => {
  return (
    <Box width={width} height={height} background={color} style={{borderRadius:"100px"}}></Box>
  )
}

export default Circle
