import React,{useContext} from 'react'
import {
  Text,
  Box,
  Header,
  Nav,
  Menu,
  ResponsiveContext,
  Image,
} from 'grommet';
import logo from '../assets/img/icon.png'
import { Menu as MenuIcon } from 'grommet-icons';
import {
  Link,
  useLocation,
  useHistory
} from "react-router-dom";
import Footer from './Footer'
import {Context as UserContext} from '../contexts/UserContext'

interface Props {
  children: any
}

const OnBoardingLayout: React.FC<Props> = ({ children }) => {

  const location = useLocation().pathname;
  const userContext = useContext(UserContext)
  const {user} = userContext

    
  const history = useHistory();

  const goTo = (url) => {
    history.push(url)
  }

  return (
  
    <Box direction="column" height={{min:"100%"}}>  


      <Box
        background="lightGrey"
        direction="row"
        justify="center"
        pad="none"
        
        border="bottom"
      >
        <Header background="lightGrey" pad={{
          vertical: "small",
          horizontal: "medium"
        }} width={{ max: '1000px' }} flex="grow">

          <Box direction="row" align="center" gap="small">
            <ResponsiveContext.Consumer >
              {responsive =>
                responsive === 'small' ? (
                  <Image
                    width="44px"
                    src={logo}
                  />
                ) : (
                    <Image
                    width="60px"
                      fit="contain"
                      src={logo}
                    />
                  )
              }
            </ResponsiveContext.Consumer>

          </Box>
          <ResponsiveContext.Consumer >
            {responsive =>
              responsive === 'small' ? (
                <Menu
                  margin="small"
                  dropAlign={{ right: 'right', top: 'bottom' }}
                  icon={(
                    <MenuIcon />
                  )}
                  items={[
                    { label:user.name?'ACCOUNTS':'LOGIN', onClick: () => { goTo(user.name?'/app/accounts':'/') } },
                    { label: 'ABOUT', onClick: () => { goTo('/about') } },
                    { label: 'CONTACT', onClick: () => { goTo('/contact') } },
                  ]}
                />
              ) : (
                  <Nav direction="row">
                    <Link to={user.name?'/app/accounts':'/'}>
                      <Text weight="bold" color={location === "/" ? "brand" : "text"} className="hl">{user.name?'ACCOUNTS':'LOGIN'}</Text>
                    </Link>
                    <Link to="/about">
                      <Text weight="bold" color={location === "/about" ? "brand" : "text"} className="hl">ABOUT</Text>
                    </Link>
                    <Link to="/contact">
                      <Text weight="bold" color={location === "/contact" ? "brand" : "text"} className="hl">CONTACT</Text>
                    </Link>
                  </Nav>
                )
            }
          </ResponsiveContext.Consumer>

        </Header>
      </Box>


      <Box
        background="background"
        justify="center"
        direction="row"
        pad={{
          vertical: "large"
        }}
        flex={{
          grow:1
        }}
      >
        <Box background="background" pad={{
          vertical: "none",
          horizontal: "medium"
        }} width={{ width:"100%", max: '1000px' }} >
          
          
          {children}


        </Box>
      </Box>

      <Box margin={{top:"xlarge"}}>
        <Footer/>
      </Box>



    </Box>
  )
}

export default OnBoardingLayout




