import React,{useContext,useEffect, useState} from 'react'
import {
  Text,
  Box,
  Header,
  Nav,
  Menu,
  ResponsiveContext,
  Image,
  Anchor
} from 'grommet';
import logo from '../assets/img/icon.png'
import { Menu as MenuIcon, User as UserIcon, Notification as NotificationIcon } from 'grommet-icons';
import {
  Link,
  useLocation,
  useHistory
} from "react-router-dom";
import Footer from './Footer'
import repo from '../api/repo'
import { Context as UserContext } from '../contexts/UserContext'
import Survey from './../views/Survey'

interface Props {
  children: any
}

const AppLayout: React.FC<Props> = ({ children }) => {

  const location = useLocation().pathname;
  const history = useHistory();
  const userContext = useContext(UserContext)
  const hasUnreadNotifications = userContext.notifications && userContext.notifications.length > 0 && (userContext.notifications[0].id > userContext.user.last_read_push_notification_id)
  const [showSurvey,setShowSurvey] = useState(false);

  const goTo = (url) => {
    history.push(url)
  }

  const signOut = async () => {   
    try {
        await repo.signOut()  
    } catch (e:any) {
    } finally {
      userContext.signOut()  
    }
  }





  return (

    <Box flex="grow" direction="column" height={{min:"100%"}}>

              {showSurvey &&
                <Survey surveyType="no-payment-logout" onComplete={signOut}/>
              }


      <Box
        background="lightGrey"
        direction="row"
        justify="center"
        pad="none"
        border="bottom"
      >
        <Header  background="lightGrey" pad={{
          vertical: "small",
          horizontal: "medium"
        }} width={{ max: '880px' }} flex="grow">

          <Box direction="row" align="center" gap="small">
            <ResponsiveContext.Consumer >
              {responsive =>
                responsive === 'small' ? (
                  <Image
                    width="44px"
                    src={logo}
                  />
                ) : (
                    <Image
                      width="60px"
                      src={logo}
                    />
                  )
              }
            </ResponsiveContext.Consumer>

          </Box>
          <Box direction="row">
            <ResponsiveContext.Consumer >
              {responsive =>
                responsive === 'small' ? (

                  <Box direction="row">

                    {/* <Anchor
                      margin={{
                        top:"small",
                        bottom:"small",
                        left:"none",
                        right:"none"
                      }}
                      color="text"
                      onClick={() => goTo('/app/notifications')}
                      icon={(
                        <NotificationIcon color={ hasUnreadNotifications?"status-critical":"text"} className={hasUnreadNotifications?"blink-me":""} />
                      )}
                    /> */}

                    <Menu
                      margin="small"
                      dropAlign={{ right: 'right', top: 'bottom' }}
                      icon={(
                        <MenuIcon color="text" />
                      )}
                      items={[
                        { label: 'ACCOUNT', onClick: () => { goTo('/app/accounts') } },
                        { label: 'ABOUT', onClick: () => { goTo('/about') } },
                        { label: 'CONTACT', onClick: () => { goTo('/contact') } },
                        // { label: 'MY PROFILE', onClick: () => { goTo('/app/profile') } },
                        { label: 'LOGOUT', onClick: () => { setShowSurvey(true) } },
                      ]}
                    />

                  </Box>
                ) : (

                    <Box direction="row">
                      <Nav direction="row" margin={{
                        top: "25px",
                        bottom: "25px",
                        right: "25px",
                        left: "0"
                      }}>
                        <Link to="/app/accounts">
                          <Text weight="bold" color={location === "/" ? "brand" : "text"} className="hl">ACCOUNTS</Text>
                        </Link>
                        <Link to="/about">
                          <Text weight="bold" color={location === "/about" ? "brand" : "text"} className="hl">ABOUT</Text>
                        </Link>
                        <Link to="/contact">
                          <Text weight="bold" color={location === "/contact" ? "brand" : "text"} className="hl">CONTACT</Text>
                        </Link>
                      </Nav>

                      <Menu

                        margin="none"
                        dropAlign={{ right: 'right', top: 'bottom' }}
                        icon={(
                          <UserIcon color="text" />
                        )}
                        items={[
                          // { label: 'MY PROFILE', onClick: () => { goTo('/app/profile') } },
                          { label: 'LOGOUT', onClick: () => { setShowSurvey(true) } },
                        ]}
                      />

                      {/* <Box margin={{
                        top: "25px",
                        bottom: "25px",
                        right: "0",
                        left: "0"
                      }}>
                        <NotificationIcon className={hasUnreadNotifications?"blink-me pointer":"pointer"} color={ hasUnreadNotifications?"status-critical":"text"} onClick={() => goTo('/app/notifications')} />
                      </Box> */}

                    </Box>


                  )
              }
            </ResponsiveContext.Consumer>



          </Box>

        </Header>
      </Box>


      <Box
        background="background"
        justify="center"
        direction="row"
        pad={{
          vertical: "large"
        }}
        flex={{
          grow:1
        }}  
      >
        <Box background="background" 
               
        pad={{
          vertical: "none",
          horizontal: "medium"
        }} width={{ width: "100%", max: '880px' }} >


          {children}


        </Box>
      </Box>

      <Box margin={{top:"xlarge"}}>
        <Footer />
      </Box>




    </Box>
  )
}

export default AppLayout




