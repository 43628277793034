import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Box, Paragraph, Text, Heading, Button } from 'grommet';
import { Checkmark } from 'grommet-icons'
import { Link, useHistory } from 'react-router-dom'
import { Context as OnBoardContext } from '../../contexts/OnBoardContext'
import repo from '../../api/repo'
import Spinner from '../../components/Spinner'
import {ReactComponent as PhoneMessageIcon} from '../../assets/svg/PhoneMessageIcon.svg'
import Toast,{Statuses} from '../../components/Toast'

interface Props { }

const OnBoardingSearchMatched: React.FC<Props> = () => {

  const onBoardContext = useContext(OnBoardContext)
  const { user } = onBoardContext
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ show: false, message: '' })


  useEffect(() => {
    if (!user.id) {
      history.push('/')
    }
    return () => {}
  }, [])

  const generateSms = async () => {
    setLoading(true)
    try {
      await repo.generateSmsCode(user)
      history.push('/sms-verification')
    } catch (e:any) {
      
        setError({ show: true, message: e.response.data.message })
      
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box margin={{ top: "100px" }} width={{ width: "100%", max: "600px" }} alignSelf="center" className="border-desktop">

<Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => { setError({ show: false, message: "" }) }} />

      <Avatar background="text" size="xlarge" alignSelf="center" margin={{ top: "-50px" }}>
        <PhoneMessageIcon fill="white"/>
      </Avatar>

      <Box alignSelf="center" justify="center">

        <Heading textAlign="center" size="medium" level={1} margin={{ bottom: "small" }} className="heading-h1" responsive={false}>CELL NUMBER</Heading>

        <Paragraph color="text-weak" margin="none" textAlign="center">
          For <Text color="text" weight="bold">{user.search}</Text> we have matched the  following cell number,<br /> does it match the pattern of your number ?
        </Paragraph>



        <Heading textAlign="center" size="medium" level={1} margin={{ bottom: "small" }} className="heading-h1" responsive={false}>
          {user.phone_number}
        </Heading>

        {!loading &&
          <Button primary onClick={() => generateSms()}>

            <Box margin="small" direction="row" justify="center" >
              <Text color="white" weight="bold" margin={{ right: "medium" }} > THATS MY NUMBER</Text> <Checkmark size="medium" color="white" />
            </Box>
          </Button>

        }
        {loading &&
          <Box margin="small" direction="row" justify="center" >
            <Text>Loading <Spinner /></Text>
          </Box>
        }



        <Paragraph color="text-weak" margin={{ top: "large", bottom: "none" }} textAlign="center">
          That's not my current cellphone number
        </Paragraph>

        <Link to="/update-number">
          <Paragraph color="brand" margin={{ top: "none", bottom: "large" }} textAlign="center"><strong><u>Change my number</u></strong></Paragraph>
        </Link>

      </Box>

    </Box>
  )
}

export default OnBoardingSearchMatched
