import React from 'react'
import { FormNext } from 'grommet-icons';

import { Box, Heading, List, Paragraph, Text } from 'grommet';

import { useHistory } from "react-router-dom";


export const items = [
  { heading: 'About Us',url:'/about-us', description: `Who we are...` },
  { heading: 'Terms & Conditions',url:'/terms-and-conditions', description: `Website terms and conditions...` },
  { heading: 'Disclaimer',url:'/disclaimer', description: `Website disclaimer..` },
  { heading: 'Privacy Policy',url:'/privacy-policy', description: `Website privacy policy...` },
];

interface Props { }


const About: React.FC<Props> = () => {

  
  const history = useHistory();

  const goTo = (item) => {
    history.push(item.url)
  }

  return (
    <div>
      <Heading level={1}>ABOUT</Heading>
      <List data={items} pad="small" border="bottom" onClickItem={event => goTo(event.item)}>
        {item => (
          <Box direction="column" gap="x-small" align="start">
            <Box direction="row" margin={{ top: "medium" }} width="100%">
              <Box flex={{grow:1}}>
                <Text weight="bold" size="medium" color="text" >{item.heading}</Text>
              </Box>
              <Box>
                <FormNext size="medium" color="brand" />
              </Box>
            </Box>

            <Box pad="none">
              <Paragraph margin="none"    color="text-weak" size="small" fill={true}>{item.description}</Paragraph>
            </Box>

           
          </Box>
        )}
      </List>
    </div>
  )
}



export default About
