import React, { createContext, useState } from 'react';


interface PropsUser{
  user?:any,
  updateUser?:any
}

interface PropsProvider {
  children:any,
 }

 /**
  * 
  */
export const Context = createContext<PropsUser>({});

/**
 * 
 * @param param0 
 */
export const Provider: React.FC<PropsProvider> = ({children}) => {

  const [user,setUser] = useState({}) 

  const updateUser = (user) => {
    setUser(user)    
  }  
 
  return(
    <Context.Provider value={{user,updateUser}}>
      {children}
    </Context.Provider>
  )
}

//export const { Consumer } = Context;






