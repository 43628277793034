import React,{useEffect,useContext,useState} from 'react'
import { Box, Heading, Paragraph, Text, ResponsiveContext } from 'grommet';
import Circle from '../../components/style/Circle'
import theme from '../../assets/style/theme'
import repo from '../../api/repo'
import {Context as UserContext} from '../../contexts/UserContext'
import Moment from 'react-moment';
import Spinner from '../../components/Spinner'
import Toast, { Statuses } from '../../components/Toast'


interface Props { }

const Notifications: React.FC<Props> = () => {

  let size = React.useContext(ResponsiveContext);
  if (size !== 'small') {
    size = 'medium'
  }

  const [error, setError] = useState({ show: false, message: '' })
  const [loading, setLoading] = useState(false)
  const userContext = useContext(UserContext)
  const [lastReadNotificationId] = useState(userContext.user.last_read_push_notification_id)
  
  useEffect(() => {     
   (
    async () => {
      setLoading(true)
      try{
        const {data} =  await repo.getNotifications()
        if(data.notifications.length>0){
          userContext.updateUser({last_read_push_notification_id:data.notifications[0].id}) 
        }
         
         userContext.updateNotifications(data.notifications)        
      }catch(e){
        setError({ show: true, message: "Whoops something went wrong!" })
      }finally{
        setLoading(false)
      }
    }
   )()   
       
    return 
    
  }, [])


  return (
    <div>
      <Heading level={1}>NOTIFICATIONS</Heading>

      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => {setError({ show: false, message: "" })}}/>

      { loading && 
        <Spinner width="75" height="75"></Spinner>
      }

      {(!loading && userContext.notifications.length==0) && 
      
          <Text>No notifications at present</Text>
        
      }

      {!loading && userContext.notifications && userContext.notifications.map( (n,i) => { 
        return (
      <Box
        key={i}
        background="white"
        border="bottom"
        width="100%"
        pad="medium"
        direction="column"
        margin={{
          left: "auto",
          right: "auto",
          top: "small",
          bottom: "small",
        }}>

        <Box direction="row">

          <Box flex={{ grow: 9 }}>
            <Paragraph margin="none" color="text-weak">
             <strong> {n.subject}</strong> - {n.message}
          </Paragraph>
          </Box>

          <Box flex={{ grow: 1 }}>
            <Box alignSelf="end">
              { lastReadNotificationId < n.id &&
              <Circle width="10px" height="10px" color={theme.global.colors['status-critical']}></Circle>
              }
            </Box>
          </Box>



        </Box>

        <Box direction="row" margin={{ top: "small" }}>
          <Box><Text color="text" weight="bold">  <Moment format="DD MMM YYYY HH:MM">{n.created_at}</Moment></Text></Box> 
          <Box flex={{ grow: 1 }}><Text color="text" alignSelf="end" ><Moment fromNow>{n.created_at}</Moment></Text></Box>
        </Box>



      </Box>

        )

      })}




    </div>
  )
}

export default Notifications
