import React from 'react'
import {Layer,Box} from 'grommet'
import Spinner from './Spinner'

const PageLoading: React.FC<any> = () => {
  return (
    <Layer full animation="fadeIn">
    <Box fill background="light-4" align="center" justify="center">
      <Spinner width="64" height="64" />
    </Box>
  </Layer>
  )
}

export default PageLoading