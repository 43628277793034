//import { css } from 'styled-components';

const theme = {
	name: "HP",
	rounding: 4,
	spacing: 20,
	scale: 1,
	defaultMode: "light",
	global: {
		colors: {
			brand: {
				dark: "#FCB03C",
				light: "#FCB03C",
			},
			background: {
				dark: "#111111",
				light: "#FFFFFF",
			},
			lightBlue:"#ADD8E6",
			lightGrey:"#F6F6F6",
			"background-back": {
				dark: "#111111",
				light: "#EEEEEE",
			},
			"background-front": {
				dark: "#222222",
				light: "#FFFFFF",
			},
			"background-contrast": {
				dark: "#FFFFFF11",
				light: "#11111111",
			},
			text: {
				dark: "#EEEEEE",
				light: "#262840",
      },
      "text-grey": {
				dark: "#555",
				light: "#555",
			},
			"text-strong": {
				dark: "#FFFFFF",
				light: "#262840",
			},
			"text-weak": {
				dark: "#CCCCCC",
				light: "#717778",
			},
			"text-xweak": {
				dark: "#999999",
				light: "#888",
			},
			white: {
				dark: "#FFF",
				light: "#FFF",
			},
			border: {
				dark: "#444444",
				light: "#DDD",
			},
			control: "brand",
			"active-background": "background-contrast",
			"active-text": "text-strong",
			"selected-background": "brand",
			"selected-text": "text-strong",
			"status-critical": "#EF6767",
			"status-warning": "#FFAA15",
			"status-ok": "#7EDC9E",
			"status-unknown": "#CCCCCC",
			"status-disabled": "#CCCCCC",
			"graph-0": "brand",
			"graph-1": "status-warning",
			focus: "brand",
		},
		font: {
			family: "Open Sans",
			size: "15px",
			height: "20px",
			maxWidth: "600px",
		},
		active: {
			background: "active-background",
			color: "active-text",
		},
		hover: {
			background: "active-background",
			color: "active-text",
		},
		selected: {
			background: "selected-background",
			color: "selected-text",
		},
		borderSize: {
			xsmall: "1px",
			small: "2px",
			medium: "3px",
			large: "10px",
			xlarge: "20px",
		},
		breakpoints: {
			small: {
				value: 640,
				borderSize: {
					xsmall: "1px",
					small: "2px",
					medium: "3px",
					large: "5px",
					xlarge: "10px",
				},
				edgeSize: {
					none: "0px",
					hair: "1px",
					xxsmall: "2px",
					xsmall: "3px",
					small: "5px",
					medium: "10px",
					large: "20px",
					xlarge: "40px",
				},
				size: {
					xxsmall: "20px",
					xsmall: "40px",
					small: "80px",
					medium: "160px",
					large: "320px",
					xlarge: "640px",
					full: "100%",
				},
			},
			medium: {
				value: 1280,
			},
			large: {},
		},
		edgeSize: {
			none: "0px",
			hair: "1px",
			xxsmall: "3px",
			xsmall: "5px",
			small: "10px",
			medium: "20px",
			large: "40px",
			xlarge: "80px",
			responsiveBreakpoint: "small",
		},
		input: {
			padding: "10px",
			weight: 600,
		},
		spacing: "20px",
		size: {
			xxsmall: "40px",
			xsmall: "80px",
			small: "160px",
			medium: "320px",
			large: "640px",
			xlarge: "960px",
			xxlarge: "1280px",
			full: "100%",
		},
	},
	chart: {},
	diagram: {
		line: {},
	},
	meter: {},
	layer: {
		background: {
			dark: "#111111",
			light: "#FFFFFF",
		},
	},
	button: {
		border: {
			width: "2px",
			radius: "4px",
		},
		padding: {
			vertical: "3px",
			horizontal: "18px",
		},
	},
	calendar: {
		small: {
			fontSize: "12.666666666666666px",
			lineHeight: 1.375,
			daySize: "22.86px",
		},
		medium: {
			fontSize: "15px",
			lineHeight: 1.45,
			daySize: "45.71px",
		},
		large: {
			fontSize: "22px",
			lineHeight: 1.11,
			daySize: "91.43px",
		},
	},
	checkBox: {
		size: "20px",
		toggle: {
			radius: "20px",
			size: "40px",
		},
	},
	clock: {
		analog: {
			hour: {
				width: "7px",
				size: "20px",
			},
			minute: {
				width: "3px",
				size: "10px",
			},
			second: {
				width: "3px",
				size: "8px",
			},
			size: {
				small: "60px",
				medium: "80px",
				large: "120px",
				xlarge: "180px",
				huge: "240px",
			},
		},
		digital: {
			text: {
				xsmall: {
					size: "10.333333333333332px",
					height: 1.5,
				},
				small: {
					size: "12.666666666666666px",
					height: 1.43,
				},
				medium: {
					size: "15px",
					height: 1.375,
				},
				large: {
					size: "17.333333333333332px",
					height: 1.167,
				},
				xlarge: {
					size: "19.666666666666668px",
					height: 1.1875,
				},
				xxlarge: {
					size: "24.333333333333336px",
					height: 1.125,
				},
			},
		},
	},
	heading: {
		level: {
			1: {
				small: {
					size: "21px",
					height: "21px",
					maxWidth: "487px",
				},
				medium: {
					size: "34px",
					height: "39px",
					maxWidth: "673px",
				},
				large: {
					size: "52px",
					height: "57px",
					maxWidth: "1047px",
				},
				xlarge: {
					size: "71px",
					height: "76px",
					maxWidth: "1420px",
				},
			},
			2: {
				small: {
					size: "22px",
					height: "27px",
					maxWidth: "440px",
				},
				medium: {
					size: "29px",
					height: "34px",
					maxWidth: "580px",
				},
				large: {
					size: "36px",
					height: "41px",
					maxWidth: "720px",
				},
				xlarge: {
					size: "43px",
					height: "48px",
					maxWidth: "860px",
				},
			},
			3: {
				small: {
					size: "20px",
					height: "25px",
					maxWidth: "393px",
				},
				medium: {
					size: "24px",
					height: "29px",
					maxWidth: "487px",
				},
				large: {
					size: "29px",
					height: "34px",
					maxWidth: "580px",
				},
				xlarge: {
					size: "34px",
					height: "39px",
					maxWidth: "673px",
				},
			},
			4: {
				small: {
					size: "17px",
					height: "22px",
					maxWidth: "347px",
				},
				medium: {
					size: "20px",
					height: "25px",
					maxWidth: "393px",
				},
				large: {
					size: "22px",
					height: "27px",
					maxWidth: "440px",
				},
				xlarge: {
					size: "24px",
					height: "29px",
					maxWidth: "487px",
				},
			},
			5: {
				small: {
					size: "14px",
					height: "19px",
					maxWidth: "277px",
				},
				medium: {
					size: "14px",
					height: "19px",
					maxWidth: "277px",
				},
				large: {
					size: "14px",
					height: "19px",
					maxWidth: "277px",
				},
				xlarge: {
					size: "14px",
					height: "19px",
					maxWidth: "277px",
				},
			},
			6: {
				small: {
					size: "13px",
					height: "18px",
					maxWidth: "253px",
				},
				medium: {
					size: "13px",
					height: "18px",
					maxWidth: "253px",
				},
				large: {
					size: "13px",
					height: "18px",
					maxWidth: "253px",
				},
				xlarge: {
					size: "13px",
					height: "18px",
					maxWidth: "253px",
				},
			},
		},
		weight: 700,
	
	},
	paragraph: {
		xsmall: {
			size: "11px",
			height: "18px",
			maxWidth: "600px",
		},
		small: {
			size: "13px",
			height: "19px",
			maxWidth: "600px",
		},
		medium: {
			size: "15px",
			height: "20px",
			maxWidth: "600px",
		},
		large: {
			size: "17px",
			height: "22px",
			maxWidth: "600px",
		},
		xlarge: {
			size: "20px",
			height: "25px",
			maxWidth: "393px",
		},
		xxlarge: {
			size: "24px",
			height: "29px",
			maxWidth: "487px",
		},
		extend: {
			whiteSpace: "pre-line",
		},
	},
	radioButton: {
		size: "20px",
	},
	text: {
		xsmall: {
			size: "11px",
			height: "18px",
			maxWidth: "253px",
		},
		small: {
			size: "13px",
			height: "19px",
			maxWidth: "277px",
		},
		medium: {
			size: "15px",
			height: "20px",
			maxWidth: "300px",
		},
		large: {
			size: "17px",
			height: "22px",
			maxWidth: "347px",
		},
		xlarge: {
			size: "20px",
			height: "25px",
			maxWidth: "393px",
		},
		xxlarge: {
			size: "24px",
			height: "29px",
			maxWidth: "487px",
		},
	},
	tabs: {
		header: {
			border: {
				side: "bottom",
				color: "border",
				size: "small",
			},
		},
	},
	tab: {
    color: "text-weak",
		active: {
			background: "background-app",
			color: "brand",
      weight: 700,
      
		},
		border: {
			side: "bottom",
			size: "small",
			color: {
				dark: "border",
				light: "border",
			},
			active: {
				color: {
					dark: "white",
					light: "white",
        },
			},
			hover: {
				color: {
					dark: "white",
					light: "black",
				},
      },
    },
   

		pad: "medium",
		margin: {
			// bring the overall tabs border behind invidual tab borders
			vertical: "-2px",
			horizontal: "none",
		},
	},
	formField: {
    label: {
      requiredIndicator: true,
    },
  },
};

export default theme;
