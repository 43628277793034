import React, { createContext, useState, useEffect } from 'react';
import repo from '../api/repo'
import PageLoading from '../components/PageLoading'

interface PropsUser{
  user?:any,
  updateUser?:any,
  notifications?:any,
  updateNotifications?:any,
  signOut?:any
}

interface PropsProvider {
  children:any,
 }

 /**
  * 
  */
export const Context = createContext<PropsUser>({});

/**
 * 
 * @param param0 
 */
export const Provider: React.FC<PropsProvider> = ({children}) => {

  const [user,setUser] = useState({}) 
  const [notifications,setNotifications] = useState([]) 
  const [loading,setLoading] = useState(true)


  //On Page load 
  useEffect(() => {

    (async () => {
     
   
      try{
        let {data} = await repo.isAuthenticated();
        if(data.user){
          setUser(data.user)
          setNotifications(data.notifications)
        }
      }catch(e){
        setUser({})
      }finally{
        setLoading(false)
      }
      
    })();        
  },[])
  
 

  const updateUser = (updateUser) => {
    setUser(updateUser)
  }

  const updateNotifications = (notifications) => {
    setNotifications(notifications)
  }

  const signOut = () => {
    setUser({})
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }
 
  return(
    <Context.Provider value={{user,updateUser,notifications,updateNotifications,signOut}}>     
      {loading 
        ? <PageLoading></PageLoading>
        : children      
      }
    </Context.Provider>
  )
}

//export const { Consumer } = Context;






