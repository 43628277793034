import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_PATH + '/',
  timeout: 60000,
  withCredentials: true,
})

// Intercept Request
api.interceptors.request.use(
  config => {
   // config.headers['cid'] = getCurrentCompanyId()
  
   config.headers['Accept'] = 'application/json';
   config.headers['Content-Type'] = 'application/json';
  
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// Intercept Response
api.interceptors.response.use(
  response => {

   if(process.env.REACT_APP_VERSION != null && response.headers['app-version'] != null){
    if (parseFloat(response.headers['app-version']) > parseFloat(process.env.REACT_APP_VERSION)) {
      window.location.reload();
    }
   }
    
    return response
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location.href = '/'
    }
    if (error.response && error.response.status === 419) {
      window.location.href = '/'
    }
    return Promise.reject(error)
  }
)


export default api
