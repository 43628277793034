import React, { useContext, useEffect, useState,useRef } from 'react'
import { Avatar, Box, Paragraph, Keyboard, Heading, Button, TextInput, Text } from 'grommet';
import { FormNextLink } from 'grommet-icons'
import { Context as OnBoardContext } from '../../contexts/OnBoardContext'
import { Context as UserContext } from '../../contexts/UserContext'
import { Link, useHistory } from 'react-router-dom'
import repo from '../../api/repo'
import Spinner from '../../components/Spinner'
import Toast,{Statuses} from '../../components/Toast'
import {ReactComponent as PhoneMessageIcon} from '../../assets/svg/PhoneMessageIcon.svg'

interface Props { }

const OnBoardingSmsVerification: React.FC<Props> = () => {

  const onBoardContext = useContext(OnBoardContext)
  const userContext = useContext(UserContext)
  const { user } = onBoardContext
  const [code, setCode] = useState('')
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  const [reSent, setReSent] = useState(false)
  const [error, setError] = useState({ show: false, message: '' })
  const inputRef = useRef<HTMLInputElement>(null);

  const generateSms = async () => {  

    setLoading(true)
    setError({ show: false, message: "" })

    try {
      await repo.generateSmsCode(user)
      setReSent(true)
      if(inputRef.current){inputRef.current.focus()}
    } catch (e:any) {
      setError({ show: true, message: e.response.data.message })
    } finally {
      setLoading(false)
    }
  }

  const smsCodeVerify = async () => {
  
    if (code === '') {
      setError({ show: true, message: "Please enter code" })
      return false;
    }

    setLoading(true)    
    setError({ show: false, message: "" })

    try {
      user.code = code
      let { data } = await repo.signIn(user)
      setLoading(false)
      userContext.updateUser(data.user)
    } catch (e:any) {
      setLoading(false)
      console.log(e)
        if(e.response.status===400){
          if(e.response.data.message=="No matching code."){
            setError({ show: true, message: "Invalid Code"})
            setCode('')
            if(inputRef.current){inputRef.current.focus()}
          }
          if(e.response.data.message=="Code expired."){
            setError({ show: true, message: "Code expired. Resend OTP Code by clicking below."})
            setCode('')
            if(inputRef.current){inputRef.current.focus()}
          }

          if(e.response.data.message=="You have tried to many times. Re Send OTP."){
            setError({ show: true, message: "To many tries. - Resend OTP Code by clicking below."})
            setCode('')
            if(inputRef.current){inputRef.current.focus()}
          }

          if(e.response.data.message=="Invalid entry - Re Send OTP"){
            setError({ show: true, message: "Whoops, Resend OTP Code by clicking below."})
            setCode('')
            if(inputRef.current){inputRef.current.focus()}
          }
         
        }else{
          setError({ show: true, message: "Something went wrong" })
        }
    } 
  }

  useEffect(() => {
    if (!user.id) {
      history.push('/')
    }

    return () => {}
  }, [])


  return (
    <Box margin={{ top: "100px" }} width={{ width: "100%", max: "600px" }} alignSelf="center" className="border-desktop">

      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => { setError({ show: false, message: "" }) }} />

      <Avatar background="text" size="xlarge" alignSelf="center" margin={{ top: "-50px" }}>
      <PhoneMessageIcon fill="white"/>
      </Avatar>

      <Box alignSelf="center" justify="center">

        <Heading textAlign="center" size="medium" level={1} margin={{ bottom: "small" }} className="heading-h1" responsive={false}>SMS VERIFICATION</Heading>

        <Paragraph color="text-weak" margin="none" textAlign="center">
          Please enter the 5 digit code we sent via SMS to <strong> {user.phone_number} </strong>
        </Paragraph>


        <Box margin={{ top: "medium", left: "auto", right: "auto" }} direction="row" width="160px">
          <Keyboard onEnter={() => { smsCodeVerify() }}>
            <TextInput        
              ref ={inputRef as any}    
              type="number"
              autoFocus
              placeholder=""
              value={code}
              onChange={event => setCode(event.target.value)}
            />
          </Keyboard>

          {!loading &&
            <Button primary margin={{ left: "small" }} onClick={() => smsCodeVerify()}>
              <Box margin={{ left: "9px", right: "9px" }}>
                <FormNextLink size="medium" color="white" />
              </Box>

            </Button>
          }
          {loading &&
            <Spinner />
          }

        </Box>

        <Paragraph color="brand" margin={{ top: "medium", bottom: "none" }} textAlign="center">
          {!loading &&
            <strong onClick={() => generateSms()} className="pointer"><u>Resend Code</u></strong>
          }
          {loading &&
            <Spinner />
          }
          {reSent &&

            <Text color="status-ok"><br /><strong>Code Resent</strong></Text>

          }
        </Paragraph>


        <Paragraph color="text-weak" margin={{ top: "large", bottom: "none" }} textAlign="center">
          That's not my current cellphone number
        </Paragraph>

        <Link to="/update-number">
          <Paragraph color="brand" margin={{ top: "none", bottom: "large" }} textAlign="center"><strong><u>Change my number</u></strong></Paragraph>
        </Link>





      </Box>

    </Box>
  )
}

export default OnBoardingSmsVerification
