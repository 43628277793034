import { Tab } from 'grommet';
import theme from '../../assets/style/theme'
import styled, { css } from "styled-components";

const ButtonTab = styled(Tab)`
  ${(props) => {
    return css`
      border-left:  ${props.active ? "solid 3px" + theme.global.colors.border.light : ""};
      border-right:  ${props.active ? "solid 3px" + theme.global.colors.border.light : ""};
      border-top: ${props.active ? "solid 3px" + theme.global.colors.border.light : ""};
      border-radius:4px;
      &:focus {
        box-shadow: none;
      }
    `;
  }}
`;

export default ButtonTab
