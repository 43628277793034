import { Heading } from 'grommet'
import React from 'react'


interface Props {

}

const AboutUs: React.FC<Props> = () => {
  return (
    <div>
      <Heading level={1}>ABOUT US</Heading>

      <p>
      HP Attorneys  has been in existence for over 30 years and has always been an integral component of commercial activity in the East Gauteng region.
      </p>

      <p>
      HP Attorneys  prides itself on best of breed collections and debt recovery disciplines. The firm has extensive expertise in providing tailor made collections and debt recovery offerings across a diverse range of products and collections life cycles where different laws could apply.

      </p>
      <p>

        Our collections and debt recovery activities are managed centrally and function nationally. We also operate across both the South and North Gauteng Divisions of the High Court as well as in eleven Magistrate Court jurisdictions thereby obviating the need for correspondent attorneys.  We are also experts in the fields of law linked to the collections and recovery process such as Debt Review, Business Rescue and insolvency practice.
</p>
      <p>
        Find out more on:
</p>
      <a href="https://hpattorneys.co.za ">https://hpattorneys.co.za </a>
    </div>
  )
}

export default AboutUs
