import React from 'react'
import { Layer, Box, Paragraph } from 'grommet'
import theme from '../assets/style/theme'

export enum Statuses {
  success,
  warning,
  danger
}

interface Props {
  status: Statuses,
  message: string,
  show: boolean,
  onClose?():void
}

const Toast: React.FC<Props> = ({ status, message, show, onClose}) => {

  const [open, setOpen] = React.useState<boolean>();

  React.useEffect(() => {
    if(show){
      setOpen(true);
      setTimeout(() => {
        setOpen(undefined);
        if(onClose){
          onClose()
        }
      }, 3000);
    }else{
      setOpen(undefined)
    }   
  },[show,onClose]);

 
  //const onClose = () => setOpen(undefined);

  var color = theme.global.colors['status-ok'];

  switch (status) {
    case Statuses.success:
      color = theme.global.colors['status-ok'];
      break;
    case Statuses.danger:
      color = theme.global.colors['status-critical']
      break;
    case Statuses.warning:
      color = theme.global.colors['status-warning'];
      break;
  }

  return (

    <div>

      {open && (

        <Layer
          position="top"
          modal={false}
          margin={{ vertical: 'medium', horizontal: 'xsmall' }}
          responsive={false}
          plain
        >
          <Box
            align="center"
            direction="row"
            gap="small"
            justify="between"
            className="rounded"
            elevation="medium"
            pad={{ vertical: 'xsmall', horizontal: 'xsmall' }}
            background={color}
          >
            <Box align="center" direction="row" gap="xsmall" margin="none" pad="small" >
              <Paragraph color="white" className="font-weight-bold" textAlign="center" margin="none">
                {message}
              </Paragraph>
            </Box>
          </Box>
        </Layer>

      )}
    </div>
  )
}

export default Toast