import React, { useState, useEffect, useContext } from 'react'
import { Box, Heading, Paragraph, Text, ResponsiveContext, TextInput, RadioButtonGroup, Button, Select,DateInput } from 'grommet';
import { Link } from 'react-router-dom'
import payat from '../../assets/img/payat.png'
import { useParams } from "react-router-dom";
import Spinner from '../../components/Spinner'
import repo from '../../api/repo'
import NumberFormat from 'react-number-format';
import Toast, { Statuses } from '../../components/Toast'
import { Context as AccountsContext } from '../../contexts/AccountsContext'
import { useHistory } from "react-router-dom";
import moment from 'moment';
import Moment from 'react-moment';
import CurrencyInput from 'react-currency-input-field';
import Survey from './../Survey';

interface Props { }

const PaymentInstoreEft: React.FC<Props> = () => {

  let size = React.useContext(ResponsiveContext);
  if (size !== 'small') {
    size = 'medium'
  }


  const [loading, setLoading] = useState(false)
  let { id } = useParams();
  const [account, setAccount] = useState<any>({});
  const [accountFound, setAccountFound] = useState(false);
  const [eft, setEft] = useState<any>({});
  const [eftFound, setEftFound] = useState(false);
  const [error, setError] = useState({ show: false, message: '' })
  const [desiredPayment, setDesiredPayment] = useState<any>({amount:"", type: null, frequency: "Weekly",first_payment_date:new Date().toISOString() });
  const [paymentDone, setPaymentDone] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);

  const accountsContext = useContext(AccountsContext)
  const { accounts, updateAccounts } = accountsContext
  const history = useHistory();


  async function fetchAccount() {
    setLoading(true);
    try {
      const { data } = await repo.account(id);
      if(moment(data.account.Matter.AodDate).isBefore(moment(), 'day') ){
        setLoading(false)
        history.push(`/app/accounts/${id}/acknowledge`)
      }else{
        fetchEft();
        setAccount(data.account);
        setAccountFound(true);
      }
    } catch (e: any) {
      setLoading(false)
      if (e.response) {        
        setError({ show: true, message: e.response.data.message })
      }
    }

  }

  useEffect(() => {     

    fetchAccount()


    return () => {
      
     }
  }, [])



    async function fetchEft() {
   
      try {
        const { data } = await repo.eftBankingDetails(id);
        setEft({
          "bank": data.bank,
          "branch": data.branch,
          "branch_code": data.branch_code,
          "account_type": data.account_type,
          "account_name": data.account_name,
          "account_number": data.account_number
        });
        setEftFound(true);
        setLoading(false)
      } catch (e: any) {
        setEftFound(false);
        setLoading(false)
      }
    }
 


  async function submit() {

    if (desiredPayment.amount == "") {
      setError({ show: true, message: "Please enter amount." })
      return false;
    }

    if (parseFloat(desiredPayment.amount) < account.Matter.MinimumPayment) {
      setError({ show: true, message: "Less then minimum amount!" })
      return false;
    } else if (parseFloat(desiredPayment.amount) > account.Matter.MatterCurrentOutstandingBalance) {
      setError({ show: true, message: "Greater then outstanding amount!" })
      return false;
    }

    if (desiredPayment.type == null) {
      setError({ show: true, message: "Please select type." })
      return false;
    }

    if(moment(desiredPayment.first_payment_date) < moment(moment().format().substr(0,10))){
      setError({ show: true, message: "Please select a future date." })
      return false;
    }

    if(moment(desiredPayment.first_payment_date) > moment(moment().add(2,"months").format().substr(0,10))){
      setError({ show: true, message: "Please select a closer date." })
      return false;
    }

    setLoading(true)

    try {
      setDesiredPayment({ ...desiredPayment });
      const { data } = await repo.eftInstore(id, {
        amount: desiredPayment.amount,
        type: desiredPayment.type,
        frequency: desiredPayment.frequency=="Once Off"?"None":desiredPayment.frequency,
        first_payment_date:desiredPayment.first_payment_date
      });

      setDesiredPayment({amount:"", type: null, frequency: "Weekly",first_payment_date:new Date().toISOString()  })
      setPaymentDone(true);
      window.scrollTo({
          top: 0,
          behavior: "smooth",
      });
      setShowSurvey(true)

    } catch (e: any) {
      setError({ show: true, message: e.response.data.message })
    } finally {
      fetchAccount();
      setLoading(false)
    }

  }


  return (
    <div>

      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => { setError({ show: false, message: "" }) }} />

      <Toast status={Statuses.success} message="Payment Arrangement Added" show={paymentDone} onClose={() => { setPaymentDone(false)}} />

      {accountFound &&
        <Box border={true} pad="small" direction="row" style={{ flexWrap: "wrap" }} className="rounded">


          <Link to="/app/accounts">
            <Text color="brand" size="small">
              <u>ACCOUNTS</u> &nbsp;
            </Text>
          </Link>

          <Link to={"/app/accounts/" + id + "/account"} >
            <Text color="brand" size="small" >
              / <u>{account.Matter.ClientSmsName.toUpperCase()}</u>&nbsp;
            </Text>
          </Link>

          <Link to={"/app/accounts/" + id + "/payment-options"}>
            <Text color="brand" size="small" >
              / <u>PAYMENTS</u> &nbsp;
            </Text>
          </Link>




          <Text color="text-weak" size="small" style={{ marginTop: "2px" }} >
            / IN STORE & EFT
          </Text>


        </Box>
      }

      {!loading && accountFound &&

        <Box>

             {showSurvey &&
                <Survey surveyType="payment-success"/>
              }



          <Box direction="row">
            <Box flex={{ grow: 1 }} align="start">
              <Heading level={1} margin={{ top: "large", bottom: "none" }}>INSTORE OR EFT</Heading>
              <Box background="text" margin={{ top: "xsmall" }} className="rounded" pad="small" direction="row">
                <Text color="white">AC# {id}</Text>
              </Box>
            </Box>
          </Box>

          <Box pad="small" background="background-back" margin={{ bottom: "small", top: "medium" }} direction="row" className="rounded">
            <Box flex={{ grow: 1 }} pad="none">
              <Paragraph color="text-grey" margin="none">Outstanding</Paragraph>
            </Box>
            <Box flex={{ grow: 1 }} pad="none">
              <NumberFormat
                value={account.Matter.MatterCurrentOutstandingBalance}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'R '}
                decimalScale={2}
                fixedDecimalScale={true}
                renderText={formattedValue => <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{formattedValue}</Paragraph>}
              />
            </Box>
          </Box>

          {/* {account.Matter.MatterCurrentOutstandingBalance > 0 && account.Matter.SettlementDiscount > 0 &&

            <Box pad="small" border={{ color: 'brand' }} margin={{ bottom: "small", top: "small" }} direction="row" className="rounded">
              <Box pad="none">
                <Paragraph color="branch" margin="none">Settlement Discount</Paragraph>
                <Text color="text-grey" margin="none" size="x-small">This is the discounted amount to pay if you <strong>settle your account in full.</strong></Text>
              </Box>
              <Box flex={{ grow: 1 }} pad="none" >
                <NumberFormat
                  value={account.Matter.MatterCurrentOutstandingBalance - account.Matter.SettlementDiscount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'R '}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  renderText={formattedValue => <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{formattedValue}</Paragraph>}
                />
              </Box>
            </Box>
          } */}

          {account.Matter.NextPaymentDate != null && moment(account.Matter.NextPaymentDate.substr(0, 10)).isValid() && (moment(account.Matter.NextPaymentDate.substr(0, 10) + "T23:59:59") >= moment()) && (account.Matter.PaymentMethod == "Pay@" || account.Matter.PaymentMethod == "EFT") &&

            <Box border pad="medium" className="rounded">
              <Paragraph color="brand"><strong>Current Payment Arrangement</strong></Paragraph>

              <Box pad="none" margin={{ bottom: "small" }} direction="row">
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph color="text-grey" margin="none">Payment Plan Frequency</Paragraph>
                </Box>
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{account.Matter.PaymentPlanFrequency}</Paragraph>
                </Box>
              </Box>

              <Box pad="none" margin={{ bottom: "small" }} direction="row">
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph color="text-grey" margin="none">Next Payment Amount</Paragraph>
                </Box>
                <Box flex={{ grow: 1 }} pad="none">
                  {account.Matter.NextPaymentAmount < 0 ?
                    <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">***</Paragraph>
                    :
                    <NumberFormat
                      value={account.Matter.NextPaymentAmount}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'R '}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      renderText={formattedValue => <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{formattedValue}</Paragraph>}
                    />
                  }
                </Box>
              </Box>

              <Box pad="none" margin={{ bottom: "small" }} direction="row">
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph color="text-grey" margin="none">Next Payment Date</Paragraph>
                </Box>
                <Box flex={{ grow: 1 }} pad="none">
                  {account.Matter.NextPaymentDate > 0 ?
                    <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">***</Paragraph>
                    :

                    <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">
                      <Moment format="DD MMM YYYY">
                        {account.Matter.NextPaymentDate}
                      </Moment>
                    </Paragraph>
                  }
                </Box>
              </Box>

              <Box pad="none" margin={{ bottom: "small" }} direction="row">
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph color="text-grey" margin="none">Payment Method</Paragraph>
                </Box>
                <Box flex={{ grow: 1 }} pad="none">
                  <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{account.Matter.PaymentMethod}</Paragraph>
                </Box>
              </Box>

              {eftFound && account.Matter.PaymentMethod == 'EFT' &&
                <Box margin={{ bottom: "medium" }}  pad={{ bottom: "small", top: "medium" }}>

                  <Paragraph margin={{ top: "medium", bottom: "medium" }} color="brand">
                    Please make an EFT payment, using the following account details.
                  </Paragraph>

             
                  <Box pad="none" margin={{ bottom: "small", top: "small" }} direction="row">
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph color="text-grey" margin="none">Bank</Paragraph>
                    </Box>
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{eft.bank}</Paragraph>
                    </Box>
                  </Box>
                  {(eft.branch != null && eft.branch != "") &&
                    <Box pad="none" margin={{ bottom: "small", top: "small" }} direction="row">
                      <Box flex={{ grow: 1 }} pad="none">
                        <Paragraph color="text-grey" margin="none">Branch</Paragraph>
                      </Box>
                      <Box flex={{ grow: 1 }} pad="none">
                        <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{eft.branch}</Paragraph>
                      </Box>
                    </Box>
                  }
                  <Box pad="none" margin={{ bottom: "small", top: "small" }} direction="row">
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph color="text-grey" margin="none">Branch Code</Paragraph>
                    </Box>
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{eft.branch_code}</Paragraph>
                    </Box>
                  </Box>
                  <Box pad="none" margin={{ bottom: "small", top: "small" }} direction="row">
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph color="text-grey" margin="none">Account Holder</Paragraph>
                    </Box>
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{eft.account_name}</Paragraph>
                    </Box>
                  </Box>
                  {/* <Box pad="none" margin={{ bottom: "small", top: "small" }} direction="row">
                  <Box flex={{ grow: 1 }} pad="none">
                    <Paragraph color="text-grey" margin="none">Account Type</Paragraph>
                  </Box>
                  <Box flex={{ grow: 1 }} pad="none">
                    <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{eft.account_type}</Paragraph>
                  </Box>
                </Box> */}
                  <Box pad="none" margin={{ bottom: "small", top: "small" }} direction="row">
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph color="text-grey" margin="none">Account Number</Paragraph>
                    </Box>
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{eft.account_number}</Paragraph>
                    </Box>
                  </Box>

                </Box>
              }


              {account.Matter.PaymentMethod == 'Pay@' &&

                <Box margin={{ bottom: "medium", top: "medium" }} border="bottom" pad={{ bottom: "small" }}>

                  <Heading level={4} color="text" margin="none">Pay@ - In Store payments</Heading>
                  <Paragraph color="brand">
                    Make a payment at one of the stores below, using the provided reference number below.
                  </Paragraph>

                  <Box pad="none" margin={{ bottom: "small", top: "medium" }} direction="row">
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph color="text-grey" margin="none">Reference Number</Paragraph>
                    </Box>
                    <Box flex={{ grow: 1 }} pad="none">
                      <Paragraph textAlign="end" className="font-weight-bold" color="text" margin="none">{account.Matter.PayAtReference}</Paragraph>
                    </Box>
                  </Box>

                  <Box margin={{ top: "medium", bottom: "medium" }} width={{ max: "600px" }} border="all">
                    <img
                      className="img-fluid"
                      src={payat}
                    />
                  </Box>

                </Box>



              }

            </Box>

          }









          <Box border pad="medium" margin={{top:"large"}} className="rounded">

            <Paragraph color="brand" margin={{ top: "small" }} ><strong>Setup a new payment Arrangement</strong></Paragraph>

            <Heading level={5} color="text" margin={{ top: "small", bottom: "xsmall" }}>How much are you going to be paying ?</Heading>
            <Box direction="row">
              <Box width="120px">
           
                 <CurrencyInput
                    className="form-control" 
                    prefix="R"
                    intlConfig={{ locale: 'en-US', currency: 'ZAR' }}
                    decimalSeparator="." 
                    groupSeparator=","
                    placeholder="Amount"
                    decimalsLimit={2}
                    onValueChange={(value, name)  => value?setDesiredPayment({...desiredPayment,amount:value}):""}
                  />
              </Box>

              {account.Matter.MinimumPayment > 0 &&
                <Box>
                  <Text color="text-weak" margin={{ left: "medium", bottom: "xsmall" }}>
                    Minimum payment of

                    <NumberFormat
                      value={account.Matter.MinimumPayment}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'R '}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      renderText={formattedValue => <Text textAlign="end" className="font-weight-bold" color="text" margin="none"> {formattedValue} </Text>}
                    />

                    required
                  </Text>
                </Box>
              }
            </Box>


            <Heading level={5} color="text" margin={{ top: "large", bottom: "xsmall" }}>How will you pay ?</Heading>
            <RadioButtonGroup
              name="doc"
              options={[{ label: 'EFT - Electronic Fund Transfer', value: 'EFT' }, { label: 'In a Store (Pay@)', value: 'Pay@' }]}
              value={desiredPayment.type}
              onChange={(event) => setDesiredPayment({ ...desiredPayment, type: event.target.value })}
            />

            <Heading level={5} color="text" margin={{ top: "medium", bottom: "xsmall" }}>How Often ?</Heading>
            <Box width={{ max: "300px" }}>
              <Select
                options={['Weekly', 'Fortnightly', 'Monthly', 'Once Off']}
                onChange={({ option }) => setDesiredPayment({ ...desiredPayment, frequency: option })}
                value={desiredPayment.frequency}
              />
            </Box>

            <Heading level={5} color="text" margin={{ top: "medium", bottom: "xsmall" }}>First Payment Date?</Heading>
            <Box width={{ max: "300px" }}>
                <DateInput
                  format="dd/mm/yyyy"
                  value={desiredPayment.first_payment_date}
                  onChange={({ value }) => {desiredPayment.first_payment_date = value}}
                />
            </Box>


            <Button onClick={() => submit()} primary margin={{ top: "medium", bottom: "medium" }} hoverIndicator={{ color: "status-ok" }} style={{width:'150px'}}>
              <Box margin="small" direction="row" justify="center">
                <Text color="white" weight="bold">SUBMIT</Text>
              </Box>
            </Button>

          </Box>

        </Box>


      }
      {loading &&
        <Box margin="medium">
          <Spinner height="64" width="64" />
        </Box>
      }



    </div>
  )
}

export default PaymentInstoreEft
