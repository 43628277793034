import React, { createContext, useState } from 'react';


interface PropsAccounts{
  payment?:any,
  updatePayment?:any
}

interface PropsProvider {
  children:any,
 }

 /**
  * 
  */
export const Context = createContext<PropsAccounts>({});

/**
 * 
 * @param param0 
 */
export const Provider: React.FC<PropsProvider> = ({children}) => {

  const [payment,setPayment] = useState({}) 

  const updatePayment = (payment) => {
    setPayment(payment)
  }
 
  return(
    <Context.Provider value={{payment,updatePayment}}>
      {children}
    </Context.Provider>
  )
}

//export const { Consumer } = Context;






