import React, { useState, useEffect,useContext } from 'react'
import { Box, Button, Paragraph, Text, CheckBox, Heading } from 'grommet';
import Toast, { Statuses } from '../../components/Toast'
import { useHistory, useParams } from "react-router-dom";
import Spinner from '../../components/Spinner'
import repo from '../../api/repo'
import { Context as AccountsContext } from '../../contexts/AccountsContext'

interface Props { }

const Accounts: React.FC<Props> = () => {

  const [checked, setChecked] = useState(false);
  const [error, setError] = useState({ show: false, message: '' })
  const history = useHistory();
  const [loading, setLoading] = useState(false)
  let { id } = useParams();
  const [account, setAccount] = useState<any>({});
  const [accountFound, setAccountFound] = useState(false);

  const accountsContext = useContext(AccountsContext)
  const { accounts,updateAccounts } = accountsContext

  const debtAcknowledged = async () => {
    if (!checked) {
      setError({ show: true, message: "Please acknowledge by ticking the tick box in order to proceed." })
      return false;
    }

    updateAccounts([...accounts,{MatterID:id,acknowledged:true}])

    try {
      await repo.updateAcknowledgement(id);
    } catch (e:any) {} 

    history.push('/app/accounts/'+id+'/account');

  }


  useEffect(() => {

    async function fetchAccount() {
      setLoading(true);
      try {
        const { data } = await repo.account(id);
        setAccount(data.account);
        setAccountFound(true);
      } catch (e:any) {
        if (e.response) {
          setError({ show: true, message: e.response.data.message })
        }
      } finally {
        setLoading(false)
      }

    }

    fetchAccount()


    return () => { }
  }, [])

  return (
    <div>

      <Toast status={Statuses.danger} message={error.message} show={error.show} onClose={() => { setError({ show: false, message: "" }) }} />

      {!loading && accountFound &&

    
        <Box>
          <Heading level={1}>{ account.Matter.ClientSmsName }</Heading>
          <Text color="text-weak">AC# { account.MatterID }</Text>
          <Paragraph color="text" size="small" style={{ maxWidth: "100%" }} margin={{ bottom: "medium" }}>
          I hereby acknowledge that I am truly indebted to the above mentioned creditor for the balance and account details as stated above.  I undertake to pay the Creditor the Capital, together with interest thereon, until payment thereof in full.  In the event of my failing to make payments on due date as agreed to, I acknowledge that the full capital together with costs and interest will become due and payable without further notice and that the Creditor’s rights against me shall not be deemed to have been waived. I consent to the jurisdiction of the Magistrate’s Court for any action arising out of this acknowledgement of debt and specifically confirm that this agreement includes Consent to Judgment in terms of the Act, in the event of default to pay in terms of this acknowledgement. I further consent that should payment not be made timeously on the due date, the Creditor, or its duly authorised agent, may without any further notice to me obtain judgment in terms of Section 57 of the Magistrate’s Court’s Act 32 of 1944 (“the Act”) based on this acknowledgement of debt.
          </Paragraph>

          <CheckBox
            checked={checked}
            label="I Acknowledge"
            onChange={(event) => setChecked(event.target.checked)}
          />


          <Button primary onClick={() => debtAcknowledged()} margin={{ top: "medium" }}>

            <Box margin="small" direction="row" justify="center">
              <Text color="white" weight="bold" > PROCEED TO ACCOUNT</Text>
            </Box>

          </Button>
        </Box>
        
      }
      {loading &&
        <Box margin="medium">
          <Spinner height="64" width="64" />
        </Box>
      }


    </div>
  )
}

export default Accounts
